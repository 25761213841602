import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { updateWaybillInfoUrl, uploadWaybillURL } from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const UpdateWaybillDialog = ({
    isModalVisible,
    handleCloseDialog,
    shipment_number
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [waybillInfo, setWaybillInfo] = useState({
        tracking_number: "",
        waybill_url: ""
    });
    const [waybillFile, setWaybillFile] = useState();
    const [waybillLoading, setWaybillLoading] = useState(false);

    useEffect(() => {
        if(shipment_number) {
            setWaybillInfo({
                shipment_number: shipment_number
            });
        }

        //eslint-disable-next-line
    }, [shipment_number]);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const uploadWaybillDocument = (e) => {
        // const fileInput = document.querySelector('#waybill-document');
        const {files} = e.target;

        const formValues = {};
        formValues["image"] = files[0];

        setWaybillLoading(true);

        axios
            .post(uploadWaybillURL, formValues, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                const waybillURL = response["data"];

                setWaybillInfo({
                    ...waybillInfo,
                    waybill_url: waybillURL
                });
                setWaybillLoading(false);
            })
            .catch(error => console.log(error));
    }

    const updateShipment = (e) => {
        e.preventDefault();

        if(waybillInfo.tracking_number === "" || waybillInfo.waybill_url === "") {
            setErrorMessage("Ensure to provide tracking number and waybill when approving manually!");
            setErrorVisibility(true);

            setTimeout(() => {
                setErrorVisibility(false);
            }, 5000);
            return;
        };

        const formValues = {
            ...waybillInfo,
        };
        // formValues['customer_id'] = adminID;

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(updateWaybillInfoUrl, newData)
            .then(function (response) {
                setIsSubmitting(false);
                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Shipment waybill updated successfully");

                    setTimeout(() => {
                        
                        handleCloseDialog();
                        window.location.reload();
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                console.log(error);
                return error;
            });
    };

    const handleInput = (e) => {
        const { value, name } = e.target;

        setWaybillInfo({
            ...waybillInfo,
            [name]: value,
        });
    };

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 className="header">UPDATE WAYBILL INFO</h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                {isError === false ? null : (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                    <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}

                <p>Update your waybill information</p>
                <div className="query-result-container">
                    <Row className="gap-3 gap-md-0 my-3 gy-4">
                        <Col className=" col-12 col-md-12">
                            <label htmlFor="Description">Tracking Number</label>
                            <input
                                name="tracking_number"
                                type="text"
                                className="form-control"
                                value={waybillInfo.tracking_number}
                                onChange={handleInput}
                            />
                        </Col>
                        <Col className=" col-12 col-md-12">
                            <div className="d-flex gap-2 align-items-center">
                                <label htmlFor="Description">Waybill Document</label>
                                {
                                    waybillLoading && (<span className="spinner-border spinner-grow-sm"/>)
                                }
                            </div>
                            <input 
                                type="file" 
                                name="waybill"
                                value={waybillFile}
                                onChange={uploadWaybillDocument}
                                id="waybill"
                                className="form-control"
                            />
                        </Col>
                    </Row>
                </div>
                <p className="mt-3">Would you like to continue?</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
                <SubmitButton
                    onClick={updateShipment}
                    type="submit"
                    className="confirm-button"
                >
                    {isSubmitting === false ? (
                        <span className="button-text">UPDATE WAYBILL INFO</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateWaybillDialog;
