import { Modal } from "react-bootstrap";
import axios from "axios";
import { massUpdateShipmentStatusUrl} from "../library/URLs";
import {useState} from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const MassUpdateShipmentStatus = ({ isModalVisible, handleCloseDialog, shipmentIDs, fetchShipmentInfo }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const updateStatus = (e) => {
        const formValues = {};
        formValues['shipment_ids'] = shipmentIDs;

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(massUpdateShipmentStatusUrl, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Shipment(s) status successfully updated");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        fetchShipmentInfo();
                        handleCloseDialog();
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">UPDATE SHIPMENT(S) STATUS</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <p>{shipmentIDs?.split("|")?.length} valid shipment(s) selected</p>
          <p>Please Ensure you have confirmed the selected shipments to be updated</p>
          <p>Note: This is only affected by Ella Logistics Cargo processors</p>

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={updateStatus} type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">UPDATE SHIPMENT</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default MassUpdateShipmentStatus;
