import styled from "styled-components";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import * as React from "react";
import { useState, useEffect } from "react";
import MakeRegularUserDialog from "../../modals/MakeRegularUserDialog";
import MarkShipmentAsPaidDialog from "../../modals/MarkShipmentAsPaidDialog";
import { BsCheckAll, BsExclamationCircleFill } from "react-icons/bs";
import { IoMdHelpCircle } from "react-icons/io";
import Tooltip from "@mui/material/Tooltip";
import {
  getShipmentPackagesURL,
  getShipmentServicesUrl,
} from "../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../library/constants";
import PackageItemsDialog from "../../modals/PackageItemsDialog";

const LoadingContainer = styled.div`
  height: 165px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Unpaid = styled.div`
  color: #924040;
  width: 95px;
  background-color: #fde1e1;
  padding: 2px 10px 2px 10px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  text-align: center;
`;

const Paid = styled.div`
  width: 95px;
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 2px 10px 2px 10px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  text-align: center;
`;

const ShipmentInformation = ({
  isRetrieving,
  amount,
  description,
  paymentMethod,
  paymentStatus,
  approvedBy,
  processor,
  itemValue,
  itemValueCurrency,
  weight,
  shipmentID,
  shipmentNumber,
  currency,
  station,
  pickupFee,
  requestedPickup,
  pickupRequestStatus,
  shipmentType,
  shipmentPlan,
  isDiscrepancy,
  weightDiscrepancy,
  insurance
}) => {
  const [showMarkShipmentAsPaidDialog, setShowMarkShipmentAsPaidDialog] =
    useState(false);

  const [showPackages, setShowPackages] = useState(false);
  const [packageItems, setPackageItems] = useState([]);
  const [shipmentServices, setShipmentServices] = useState([]);
  const [packagesGroup, setPackagesGroup] = useState([]);

  const data = {
    shipment_number: shipmentNumber,
  };

  const newData = { ...data, ...UserProfileData() };

  const groupByPackage = (shipments) => {
    const grouped = {};

    shipments.forEach((shipment) => {
      const packageKey = shipment.package;

      if (!grouped[packageKey]) {
        grouped[packageKey] = {
          name: `Package ${packageKey}`,
          dimensions: {
            package_length: shipment.package_length,
            package_width: shipment.package_width,
            package_height: shipment.package_height,
          },
        };
      }
    });

    return Object.values(grouped);
  };

  useEffect(() => {
    getShipmentServices();
    fetchShipmentPackages();
    // eslint-disable-next-line
  }, [shipmentNumber]);

  const getShipmentServices = async () => {
    axios
      .post(
        getShipmentServicesUrl,
        { ...newData, shipment_no: shipmentNumber },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response["data"]["success"] === false) return;

        setShipmentServices(response["data"]["data"]);
      });
  };

  const fetchShipmentPackages = async () => {
    await axios
      .post(getShipmentPackagesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        console.log("poas", response["data"]);
        let transformed_group = groupByPackage(response["data"]);
        console.log("dfaf", transformed_group);
        setPackageItems(response["data"]);
        setPackagesGroup(transformed_group);
      });
  };

  const toggleMarkShipmentAsPaidDialog = () => {
    setShowMarkShipmentAsPaidDialog(!showMarkShipmentAsPaidDialog);
    console.log("hello");
  };

  const handleCloseModal = () => {
    setShowMarkShipmentAsPaidDialog(false);
  };

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Click to view all packages
  //   </Tooltip>
  // );

  return (
    <div className="col-xl-12">
      <MarkShipmentAsPaidDialog
        isModalVisible={showMarkShipmentAsPaidDialog}
        handleCloseDialog={handleCloseModal}
        shipmentID={shipmentID}
      />

      <PackageItemsDialog
        packages={packageItems}
        isModalVisible={showPackages}
        handleCloseDialog={() => setShowPackages(false)}
      />

      <span className="header">Shipment Information</span>
      {isRetrieving === true ? (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      ) : (
        <div className="card bg-light border-0 shadow">
          <div className="card-body shipper-container">
            <div className="row">
              <div className="col-xl-3">
                <span className="title">Amount</span>
                <p>
                  {currency === "USD"
                    ? "$"
                    : currency === "NGN"
                    ? "₦"
                    : currency === "GBP"
                    ? "£"
                    : currency === "EUR"
                    ? "€"
                    : "₦"}
                  {parseFloat(amount).toLocaleString("en")}
                </p>
              </div>
              <div
                className="col-xl-3"
                style={{ cursor: "pointer" }}
                onClick={() => setShowPackages(true)}
              >
                <span className="title d-flex gap-1 align-items-start">
                  Description
                  <div className="position-relative">
                    <Tooltip
                      title="Click here to view packages"
                      placement="top"
                    >
                      <span>
                        <IoMdHelpCircle />
                      </span>
                    </Tooltip>
                  </div>
                </span>
                <p>{description}</p>
              </div>
              <div className="col-xl-3">
                <span className="title">Payment Method</span>
                <p>{paymentMethod}</p>
              </div>
              <div className="col-xl-3">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <span className="title">Payment Status</span>
                  {paymentStatus === "Unpaid" ? (
                    <BsCheckAll
                      onClick={toggleMarkShipmentAsPaidDialog}
                      style={{ width: 20, cursor: "pointer", color: "green" }}
                    />
                  ) : null}
                </div>
                {paymentStatus === "" ? null : paymentStatus === "Paid" ? (
                  <Paid>{paymentStatus}</Paid>
                ) : (
                  <Unpaid>{paymentStatus}</Unpaid>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3">
                <span className="title">Item Value</span>
                <p>
                  {itemValue === null || itemValue === ""
                    ? "Undefined"
                    : itemValueCurrency +
                      " " +
                      parseFloat(itemValue).toLocaleString("en")}
                </p>
              </div>
              <div className="col-xl-3">
                <span className="title">Weight</span>
                <p>
                  {weight === null || weight === ""
                    ? "Undefined"
                    : weight + "KG"}
                </p>
              </div>
              <div className="col-xl-3">
                <span className="title">Transporter</span>
                <p>{processor}</p>
              </div>
              <div className="col-xl-3">
                <span className="title">Approved By</span>
                <p>{approvedBy}</p>
              </div>
              {station && (
                <div className="col-xl-3">
                  <span className="title">Processing Station</span>
                  <p>{station}</p>
                </div>
              )}
              {isDiscrepancy === "Yes" && (
                <div className="col-xl-3">
                  <span className="title">DHL Weight</span>
                  <Tooltip title="Detected Weight Discrepancy" placement="top">
                    <span className="d-flex align-items-start gap-1" >
                      <p>{weightDiscrepancy} KG</p>
                      <BsExclamationCircleFill
                        style={{ fontSize: 14, color: "red" }}
                      />
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-xl-3">
                <span className="title">Insurance</span>
                <p>{insurance}</p>
              </div>
              {shipmentType === "Local" &&
                UserProfileData()?.company_id === "123" && (
                  <div className="col-xl-3">
                    <span className="title">Local Pickup Plan</span>
                    <p>{shipmentPlan}</p>
                  </div>
                )}
              {UserProfileData()?.company_id === "123" && (
                <div className="col-xl-3">
                  <span className="title">Requested Pickup</span>
                  <p>{requestedPickup}</p>
                </div>
              )}
              {UserProfileData()?.company_id === "123" && (
                <div className="col-xl-3">
                  <span className="title">Pickup Fee</span>
                  <p>
                    {currency === "USD"
                      ? "$"
                      : currency === "NGN"
                      ? "₦"
                      : currency === "GBP"
                      ? "£"
                      : currency === "EUR"
                      ? "€"
                      : "₦"}
                    {parseFloat(pickupFee).toLocaleString("en")}
                  </p>
                </div>
              )}
              {requestedPickup === "Yes" && (
                <div className="col-xl-3">
                  <span className="title">Pickup Request Status</span>
                  <p>{pickupRequestStatus}</p>
                </div>
              )}
              {shipmentServices?.length > 0 && (
                <div className="col-xl-3">
                  <span className="title">Additional Services</span>
                  <p>
                    {shipmentServices
                      ?.map((service) => service?.service_name)
                      ?.join(", ")}
                  </p>
                </div>
              )}
              {packagesGroup?.length > 0 && (
                <div className="col-xl-12">
                  <span className="title">Packages Dimensions</span>
                  <div className="row mb-2">
                    <div className="col-3" style={{ fontWeight: 700 }}>
                      Package
                    </div>
                    <div className="col-3" style={{ fontWeight: 700 }}>
                      Length
                    </div>
                    <div className="col-3" style={{ fontWeight: 700 }}>
                      Width
                    </div>
                    <div className="col-3" style={{ fontWeight: 700 }}>
                      Height
                    </div>
                  </div>
                  {packagesGroup?.map((pck_grp, id) => (
                    <div className="row mb-1">
                      <div className="col-3">{pck_grp?.name} </div>
                      <div className="col-3">
                        {pck_grp?.dimensions?.package_length} CM
                      </div>
                      <div className="col-3">
                        {pck_grp?.dimensions?.package_width} CM
                      </div>
                      <div className="col-3">
                        {pck_grp?.dimensions?.package_height} CM
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipmentInformation;
