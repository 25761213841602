import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { UserProfileData } from "../../library/constants";
import {getAllTransporterActivitiesURL, getRecentTransporterActivitiesURL} from "../../library/URLs";

import * as React from "react";
import styled from "styled-components";
import moment from "moment";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TransporterActivities = () => {
  const [isRetrieving, setIsRetrieving] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [transporterActivities, setTransporterActivities] = useState([]);

  useEffect(() => {
    axios
      .post(getAllTransporterActivitiesURL, UserProfileData(), {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setIsRetrieving(false);

          if (response["data"]["success"] === false) return;

          const transporterActivities = response["data"]["data"];

          if (transporterActivities.length === 0)
            setIsEmpty(true);

          setTransporterActivities(transporterActivities);
        });
  }, []);

  return (
    <div className="col-xl-4 col-md-12 mb-4 mb-xl-0">
      <div className="card bg-light border-0 shadow">
        <div className="card-body transporter-activity-container">
          <h6>Transporter Activities</h6>
          {
            isRetrieving === true ?
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                </LoadingContainer> : isEmpty === true ?
                <EmptyContainer>
                  <p>Oops... Sorry, you have no transporter activity</p>
                </EmptyContainer> :
                <div className="transporter-activity-body">
                  {transporterActivities.map((activity) => {
                      const dateTime = moment(activity.date_created);
                      const timestamp = dateTime.format('ddd MMM Do, h:mm A');
                    return (
                        <div key={activity.id} className="d-flex transporter-activity-row">
                          <img
                              src={activity.rider_profile_image_url}
                              className="avatar"
                              alt=""
                          />
                          <div className="d-flex flex-column">
                            <span className="date-title">{timestamp}</span>
                            <span className="body-text">
                                {activity.description}
                              </span>
                          </div>
                          <hr />
                        </div>
                    );
                  })}
                </div>
          }
        </div>
      </div>
    </div>
  );
};

export default TransporterActivities;
