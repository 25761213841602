import { Col, Modal, Row } from "react-bootstrap";
import { createRef, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { editTransporterURL, topupUserWalletURL, uploadPackageImgUrl, getCompanyInsuranceUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { BiX } from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const EditContentDialog = ({ isModalVisible, handleCloseDialog, packageObj, handleSubmit, shipmentType, insurance }) => {
    const [formData, setFormData] = useState({
        description: "",
        weight: "",
        quantity: "",
        value: "",
        hs_code: "",
        image_url: ""
    });

    const fileInputRef = useRef(null);

    React.useEffect(() => {
        if (isModalVisible) {
            let value = {
                ...formData,
                description: packageObj?.description,
                weight: packageObj?.weight,
                quantity: packageObj?.quantity,
                value: packageObj?.value,
                image_url: packageObj?.image_url
            };
            if (shipmentType === "International") {
                value["hs_code"] = packageObj?.hs_code
            }
            setFormData(value);
        };

        getCompanyInsurance();
        // eslint-disable-next-line
    }, [isModalVisible])

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [companyInsurance, setCompanyInsurance] = useState();

    const handleInput = (e) => {
        const { name, value } = e.target;

        if(name === "description") {
            setFormData({
                ...formData,
                [name]: value,
                name: value
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (
            formData?.weight === "" ||
            formData?.value === "" ||
            formData?.description === "" ||
            formData?.quantity === ""
        ) {
            setErrorVisibility(true);
            setErrorMessage("Please ensure to fill all fields!");

            setTimeout(() => {
                setErrorVisibility(false);
            }, 5000);
            return;
        }

        if(companyInsurance?.insurance_company === "MyCoverAI" && companyInsurance?.status === "Active"
            && (insurance === "Yes" || insurance === "Capped")
        ) {
            if(formData?.image_url === "") {
                setErrorVisibility(true);
                setErrorMessage("Please ensure to upload item image!");
    
                setTimeout(() => {
                    setErrorVisibility(false);
                }, 5000);
                return;
            }
        }

        if (shipmentType === "International") {
            if (
                formData?.hs_code === ""
            ) {
                setErrorVisibility(true);
                setErrorMessage("Please ensure to fill all fields!");

                setTimeout(() => {
                    setErrorVisibility(false);
                }, 5000);
                return;
            }
        };
        console.log("DAf", formData);
        handleSubmit(formData);
        handleCloseDialog();
    };

    const [uploading, setUploading] = useState(false);
    const [packageImg, setPackageImg] = useState("");
    const [imgUrl, setUrl] = useState("");

    const uploadPackageImage = (file) => {
        setErrorVisibility(false);

        const imgFormData = new FormData();
        imgFormData.append("image", file);

        setUploading(true);

        axios
            .post(uploadPackageImgUrl, imgFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (response) {
                setUploading(false);
                setFormData({
                    ...formData,
                    image_url: response?.data
                })
            })
            .catch(function (error) {
                return error;
            });
    };

    const getCompanyInsurance = () => {

        const data = {
            ...UserProfileData()
        }

        axios
            .post(getCompanyInsuranceUrl, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(function (response) {
                setCompanyInsurance(
                    response["data"]["data"]
                );
            })
            .catch(function (error) {
                return error;
            });
    };

    const handleFileInput = (e) => {
        const { files } = e.target;
        setPackageImg(files[0]?.name);
        uploadPackageImage(files[0]);
    };

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 style={{ marginTop: 10 }} className="header">EDIT PACKAGE ITEM</h5>
                <BiX style={{ fontSize: 25, cursor: "pointer" }} onClick={handleCloseDialog} />
            </Modal.Header>
            <Modal.Body>
                {
                    isError === false ? null :
                        (<ErrorMessageContainer>
                            {errorMessage}
                        </ErrorMessageContainer>)
                }

                {
                    isSuccess === false ? null :
                        (<SuccessMessageContainer>
                            {successMessage}
                        </SuccessMessageContainer>)
                }

                <form >
                    <div>
                        <div className="mt-3">
                            <label htmlFor="Amount">Description</label>
                            <input name="description" className="form-control" type="tel" value={formData?.description} onChange={handleInput} />
                        </div>
                        {
                            shipmentType === "International" ? (
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="Description">Value</label>
                                        <input name="value" className="form-control" type="tel" value={formData?.value} onChange={handleInput} />
                                    </Col>
                                    <Col>
                                        <label htmlFor="User Name">HS Code</label>
                                        <input name="hs_code" className="form-control" type="tel" value={formData?.hs_code} onChange={handleInput} />
                                    </Col>
                                </Row>
                            ) : (
                                <div className="mt-2">
                                    <label htmlFor="Description">Value</label>
                                    <input name="value" className="form-control" type="tel" value={formData?.value} onChange={handleInput} />
                                </div>
                            )
                        }
                        <Row className="mt-2">
                            <Col>
                                <label htmlFor="User Name">Weight</label>
                                <input name="weight" className="form-control" type="tel" value={formData?.weight} onChange={handleInput} />
                            </Col>
                            <Col>
                                <label htmlFor="User Name">Quantity</label>
                                <input name="quantity" className="form-control" type="tel" value={formData?.quantity} onChange={handleInput} />
                            </Col>
                        </Row>
                        {
                            companyInsurance?.insurance_company === "MyCoverAI" && companyInsurance?.status === "Active" &&
                            (insurance === "Yes" || insurance === "Capped") && (
                                <Row className="mt-2" >
                                    <Col>
                                        <label htmlFor="package img" >Upload Package Image</label>
                                        <div className="d-flex gap-2 align-items-center" >
                                            {
                                                uploading && (
                                                    <span className="spinner-border spinner-grow-sm" />
                                                )
                                            }
                                            <div className="w-100" >
                                                <input
                                                    style={{
                                                        visibility: "hidden",
                                                        position: "fixed",
                                                        bottom: 0,
                                                        zIndex: -1,
                                                    }}
                                                    type="file"
                                                    onChange={handleFileInput}
                                                    ref={fileInputRef}
                                                    accept="image/*"
                                                    name=""
                                                    id=""
                                                />
                                                <div
                                                    style={{ marginTop: 20 }}
                                                    onClick={triggerFileInput}
                                                    className="change-password-button"
                                                >
                                                    Choose Image
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            formData?.image_url !== "" && (
                                                <small>{packageImg}</small>
                                            )
                                        }
                                    </Col>
                                </Row>
                            )
                        }
                    </div>
                </form>

            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
                <SubmitButton onClick={onSubmit} type="button" className="confirm-button">
                    EDIT
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditContentDialog;
