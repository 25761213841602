import { Modal } from "react-bootstrap";
import { BiX } from "react-icons/bi";
import * as React from "react";

const ProhibitedItemsDialog = ({ isModalVisible, handleCloseDialog }) => {
    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog} size="lg">
            <Modal.Header>
                <h5 style={{ marginTop: 10 }} className="header">
                    LIST OF PROHIBITED ITEMS
                </h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                <div className="state-dialog-body">
                    <p className="mb-3">
                        Before you ship with BOT Express, it's important to check whether
                        your items are prohibited or restricted.
                        <br />
                        The following commodities are not accepted by BOT Express under any
                        circumstances.
                    </p>
                    <ul className="order-item-list">
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Live animals: Hunting (animal) trophies, animal parts such as
                                ivory and sharks fin, animal remains, or animal-by-products or
                                derived products not intended for human consumption, prohibited
                                for movement by the CITES Convention or local law
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Human remains or ashes in any form
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Bullion (of any precious metal)
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Cash (current legal tender as bank notes, currency notes, coins)
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Loose precious and semi-precious stones (cut or uncut, polished
                                or unpolished)
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Complete firearms, ammunition, explosives, explosive devices,
                                including inert explosives or test pieces, air guns, replica or
                                imitation firearms or ammunition
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Illegal goods, such as illicit drugs, included but not limited
                                to narcotics stimulants, depressants or hallucinogens. Cannabis
                                or its derivatives
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Counterfeit goods in breach of intellectual property rights
                                (IPR)
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Cigarettes, cigars, tobacco products and electronic cigarettes
                            </p>
                        </li>
                        <li className="order-item-row">
                            <p className="order-item-header first-order">
                                Dangerous/hazardous goods including but not limited to perfumes,
                                aftershaves, aerosols, flammable substances, dry ice, biological
                                substances
                            </p>
                        </li>
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ProhibitedItemsDialog;
