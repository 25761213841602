import { Button, Modal } from "react-bootstrap";
import {useEffect, useState} from "react";
import {UserProfileData} from "../library/constants";
import axios from "axios";
import {getAllTransportersURL, getCitiesURL, getStatesURL, routeShipmentURL} from "../library/URLs";
import * as BiIcons from "react-icons/bi";
import {BiX} from "react-icons/bi";
import * as React from "react";
import styled from "styled-components";

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const CityListDialog = ({ countryCode, stateName, isModalVisible, handleCloseDialog }) => {
    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [transporters, setCities] = useState([]);

    const [selectedState, setSelectedState] = useState("");

    useEffect(() => {
        const formValues = {};
        formValues['country'] = countryCode;
        formValues['state'] = stateName;

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        axios
            .post(getCitiesURL, newData)
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    const cities = response["data"]["data"];
                    cities.forEach((city) => {
                        city["selected"] = false;
                    })

                    setCities(cities);
                }
            })
            .catch(function (error) {
                return error;
            });
    }, [countryCode, stateName]);

    const routeShipment = () => {
        handleCloseDialog(selectedState);
    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">SELECT CITY</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <div className="state-dialog-body">
              {
                  transporters.map((city) => {
                      const selectCity = (cityName) => {
                          let newCity = [...transporters];
                          newCity.forEach((city) => {
                              city["selected"] = false;
                          });

                          const stateIndex = transporters.findIndex((city) => city.name === cityName);

                          newCity[stateIndex] = {...newCity[stateIndex],
                              selected: !newCity[stateIndex].selected};

                          setCities(newCity);

                          setSelectedState(newCity[stateIndex].name);
                      }
                      return(
                          <div className="single-state-row" onClick={(e) => selectCity(city.name)} key={city.name}>
                              <span>{city.name}</span>
                              {city.selected === false ? null : <BiIcons.BiCheck style={{fontSize:20, float:"right"}}/>}
                          </div>
                      );
                  })
              }
          </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={routeShipment} type="button" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">SELECT CITY</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CityListDialog;
