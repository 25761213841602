import { Button, Modal } from "react-bootstrap";
import {useEffect, useState} from "react";
import {UserProfileData} from "../library/constants";
import axios from "axios";
import {getAllTransportersURL, rerouteShipmentURL} from "../library/URLs";
import * as BiIcons from "react-icons/bi";
import {BiX} from "react-icons/bi";
import * as React from "react";
import styled from "styled-components";

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ReRouteShipmentDialog = ({ shipmentID, isModalVisible, handleCloseDialog }) => {
    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [transporters, setTransporters] = useState([]);

    const [selectedTransporter, setSelectedTransporter] = useState("");

    useEffect(() => {
        console.log(UserProfileData());
        axios
            .post(getAllTransportersURL, UserProfileData())
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    const transporters = response["data"]["data"];
                    transporters.forEach((transporter) => {
                        transporter["selected"] = false;
                    })
                    console.log(transporters);

                    setTransporters(transporters);
                }
            })
            .catch(function (error) {
                return error;
            });
    }, []);

    const rerouteShipment = () => {
        const formValues = {};
        formValues['rider'] = selectedTransporter;
        formValues['shipment_id'] = shipmentID;

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(rerouteShipmentURL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Shipment has successfully been rerouted");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog();
                    }, 5000);
                }
            })
            .catch(function (error) {
                return error;
            });
    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">REROUTE TO TRANSPORTER</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          {
              transporters.map((transporter) => {
                  const selectTransporter = (transporterID) => {
                      let newTransporters = [...transporters];
                      newTransporters.forEach((transporter) => {
                          transporter["selected"] = false;
                      });

                      const transporterIndex = transporters.findIndex((transporter) => transporter.id === transporterID);

                      newTransporters[transporterIndex] = {...newTransporters[transporterIndex],
                          selected: !newTransporters[transporterIndex].selected};

                      setTransporters(newTransporters);

                      setSelectedTransporter(transporterID);
                  }
                  return(
                      <div onClick={(e) => selectTransporter(transporter.id)} style={{marginBottom:10, cursor:"pointer"}} key={transporter.id}>
                          <img style={{width:35, height:35, borderRadius:"50%", marginRight:10}} src={transporter.rider_profile_image_url}  alt=""/>
                          <span>{transporter.name}</span>
                          {transporter.selected === false ? null : <BiIcons.BiCheck style={{fontSize:20, float:"right"}}/>}
                      </div>
                  );
              })
          }
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={rerouteShipment} type="button" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">REROUTE SHIPMENT</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ReRouteShipmentDialog;
