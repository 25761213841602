import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import {
    getShipmentsURL,
    searchForCustomersURL,
    searchForShipmentsURL,
    advanceShipmentSearchUrl,
    getPackagingURL,
} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import RouteShipmentDialog from "../modals/RouteShipmentDialog";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import { SessionManagement } from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";
import SearchShipmentsDialog from "../modals/SearchShipmentsDialog";
import { BsDot } from "react-icons/bs";
import * as ExcelJS from "exceljs";
import AddBatchDialog from "../modals/AddBatchDialog";
import AddPackageDialog from "../modals/AddPackageDialog";
import DeletePackageDialog from "../modals/DeletePackageDialog";
import EditPackageDialog from "../modals/EditPackageDialog";

function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
}

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EditBtn = styled.button`
  width: 110px;
`;

const DeleteBtn = styled.button`
  width: 110px;
  background-color: #f46a6a;
`;

const Packages = () => {
    const form = useRef(null);

    const [chosenDate, setChosenDate] = useState("");

    const [addPackage, setAddPackage] = useState(false);
    const [deletePackaging, setDeletePackaging] = useState(null);
    const [editPackaging, setEditPackaging] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [currentOffsetDate, setCurrentOffsetDate] = useState("");
    const [currentOffsetID, setCurrentOffsetID] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);

    const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] =
        useState(false);

    const [shipments, setShipments] = useState([]);
    const [filtered, setFiltered] = useState([]);

    // advance search fields

    const data = {
        limit: 20,
        offset_date: currentOffsetDate,
        offset_id: currentOffsetID,
        date: chosenDate,
    };

    const newData = { ...data, ...UserProfileData() };
    console.log(newData);

    const fetchPackages = () => {
        axios
            .post(getPackagingURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                // if (isLoading === false && isRetrievingMore === false) {
                //     console.log("Should not retrieve");
                //     return;
                // }
                console.log(response["data"]);

                setIsRetrievingMore(false);
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const newShipments = response["data"]["data"];

                // if (newShipments.length > 0) {
                //     const offsetID = newShipments[newShipments.length - 1].id;
                //     const offsetDate = newShipments[newShipments.length - 1].date_time;

                //     setCurrentOffsetID(offsetID);
                //     setCurrentOffsetDate(offsetDate);
                // }

                setShipments(newShipments);
                setFiltered(newShipments);
            });
    };

    useEffect(() => {
        fetchPackages();
    }, [isRetrievingMore]);

    // toggle selected shipments

    const setScroll = () => {
        if (
            window.innerHeight + window.scrollY >= document.body.offsetHeight &&
            isRetrievingMore === false
        ) {
            if (isRetrievingMore === false) {
                console.log("It's receiving");
                setIsRetrievingMore(true);
            } else {
                console.log("It's not receiving");
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", setScroll);
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);

    const toggleCustomDateRangeDialog = () => {
        setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
    };

    const handleQueryChange = (e) => {
        const { value } = e.target;
        setFiltered(
            shipments?.filter((ship) =>
                ship?.name?.toLowerCase()?.includes(value?.toLowerCase())
            )
        );
    };

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <AddPackageDialog
                    isModalVisible={addPackage}
                    handleCloseDialog={() => setAddPackage(false)}
                    onSubmit={fetchPackages}
                />
                <DeletePackageDialog
                    isModalVisible={deletePackaging !== null}
                    handleCloseDialog={() => setDeletePackaging(null)}
                    packagingID={deletePackaging}
                    updatePackagings={fetchPackages}
                />
                <EditPackageDialog
                    isModalVisible={editPackaging !== null}
                    handleCloseDialog={() => setEditPackaging(null)}
                    onSubmit={fetchPackages}
                    packaging={editPackaging}
                />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5>Packages</h5>
                        <span>Home</span>
                    </div>
                    <div className="container-fluid shipments-container">
                        <CustomDateRangeDialog
                            isModalVisible={isCustomDateRangeDialogVisible}
                            handleCloseDialog={(e) => {
                                if (typeof e === "string") {
                                    console.log("The chosen date is " + e.toString());
                                    setChosenDate(e);
                                }
                                toggleCustomDateRangeDialog("close");
                            }}
                        />

                        <div className="card bg-light border-0 shadow p-4 min-vh-100">
                            <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
                                <div
                                    className="d-flex flex align-items-center"
                                    style={{ width: "70%" }}
                                >
                                    <form
                                        className="query-result-container"
                                        style={{ width: "50%" }}
                                        ref={form}
                                    >
                                        <input
                                            onChange={handleQueryChange}
                                            name="query"
                                            className="form-control"
                                            type="text"
                                            placeholder="Search using package name"
                                        />
                                    </form>
                                </div>

                                {/* <input className="confirm-button" type="button" onClick={exportToXlsx}
                                    value="DOWNLOAD AAJ FEDEX" /> */}

                                <input
                                    className="confirm-button"
                                    type="button"
                                    onClick={() => setAddPackage(true)}
                                    value="ADD PACKAGE"
                                />
                            </div>

                            <div className="table-contain w-100">
                                <table className="shipments-table w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col">Package Name</th>
                                            <th scope="col">Length(CM)</th>
                                            <th scope="col">Width(CM)</th>
                                            <th scope="col">Height(CM)</th>
                                            <th scope="col">Weight(KG)</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    {isLoading === true ? null : (
                                        <tbody>
                                            {filtered.map((shipment) => {
                                                return (
                                                    <tr key={shipment.id}>
                                                        <td>{shipment.name}</td>
                                                        <td>{shipment.length}</td>
                                                        <td>{shipment.width}</td>
                                                        <td>{shipment.height}</td>
                                                        <td>{shipment.weight}</td>
                                                        <td className="d-flex flex-column gap-1">
                                                            <EditBtn
                                                                className="confirm-button"
                                                                onClick={() => setEditPackaging(shipment)}
                                                            >
                                                                Edit
                                                            </EditBtn>
                                                            <DeleteBtn
                                                                className="confirm-button"
                                                                onClick={() => setDeletePackaging(shipment.id)}
                                                            >
                                                                Delete
                                                            </DeleteBtn>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {isRetrievingMore === false ? null : (
                                <div style={{ textAlign: "center", marginTop: 40 }}>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                                    Retrieving More..
                                </div>
                            )}

                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm" />
                                </LoadingContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Packages;
