import "../../styles/login.scss";
import styled from "styled-components";
import { loginURL, updateOperatorURL, verifyOTPURL } from "../../library/URLs";
import { useRef, useState } from "react";
import axios from "axios";
import { ErrorNotification } from "../../components/Notifications";
import { useNavigate } from "react-router-dom";
import { generateTransactionID } from "../../library/utilities";
import { SessionManagement } from "../../library/SessionManagement";
import OtpInput from "react-otp-input";
import { UserProfileData } from "../../library/constants";

const PageHeader = styled.h4`
  font-family: Prompt, sans-serif;
  font-size: 55px;
  text-align: center;
  margin-bottom: 10px;
  color: #0b613a;

  @media(max-width:556px) {
    font-size: 2.4rem;
  }
`;

const PageSubheader = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: normal;
`;

const PageFooter = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const UpdateOperator = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [operator, setOperator] = useState("Business");

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const form = useRef(null);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();
    const formValues = {};

    // for (let [key, value] of data.entries()) {
    //   formValues[key] = value;
    // }

    formValues["device_id"] = "value";
    formValues["device_name"] = "WEB";
    formValues["token"] = "value";
    formValues["transaction_id"] = generateTransactionID(15);

    setIsSubmitting(true);
    setIsLoading(true);

    axios
      .post(updateOperatorURL, {
        ...UserProfileData(),
        operator_type: operator,
      })
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          setIsLoading(false);

          navigate("/login", { replace: true });
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  return (
    <div className="box-container">
      {isError === true ? (
        <ErrorNotification message={errorMessage}>
          This is a notification
        </ErrorNotification>
      ) : null}
      <PageHeader className="page-header">Operator Type</PageHeader>
      <PageSubheader>
        Already have an account? <a href="/">Login</a>
      </PageSubheader>
      <div>
        <div
          className={`Operator-item ${
            operator === "Business" && "Active-Operator"
          } mb-4`}
          onClick={() => setOperator("Business")}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p
                className={`Operator-header ${
                  operator === "Business" && "Active-header"
                }`}
              >
                Business Operator
              </p>
              <div>
                <input
                  type="radio"
                  checked={operator === "Business"}
                  onClick={() => setOperator("Business")}
                />
              </div>
            </div>

            <p
              className={`Operator-info ${
                operator === "Business" && "Active-info"
              }`}
            >
              My logistics business currently has bikes, staff and/or office
            </p>
          </div>
        </div>
        <div
          className={`Operator-item ${
            operator === "Service" && "Active-Operator"
          }`}
          onClick={() => setOperator("Service")}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p
                className={`Operator-header ${
                  operator === "Service" && "Active-header"
                }`}
              >
                Service Operator
              </p>
              <div>
                <input
                  type="radio"
                  checked={operator === "Service"}
                  onClick={() => setOperator("Service")}
                />
              </div>
            </div>

            <p
              className={`Operator-info ${
                operator === "Service" && "Active-info"
              }`}
            >
              I want to create a logistics business without bikes, vans or buses
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row my-5 justify-content-end">
        <button type="submit" className="login-button" onClick={handleSubmit}>
          {isLoading === false ? (
            <span className="button-text">SUBMIT</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </button>
      </div>
    </div>
  );
};

export default UpdateOperator;
