export const weightSummation = (arr) => {
    return arr.reduce((sum, current) => {
      const weight = Number(current.weight);
      return sum + (isNaN(weight) ? 0 : weight);
    }, 0);
  };
  
  export const valueSummation = (arr) => {
    return arr.reduce((sum, current) => {
      const value = Number(current.value);
      return sum + (isNaN(value) ? 0 : value);
    }, 0);
  };

  export function getTotalValue(items) {
    let totalValue = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const quantity = parseFloat(item.quantity);
      const value = parseFloat(item.value);
      totalValue += quantity * value;
    }
    return totalValue;
  }