import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import "../../styles/create-local-shipment.scss";
import {
    createStationUrl, editStationUrl, getStationsURL,
} from "../../library/URLs";
import ReactFlagsSelect from "react-flags-select";
import { useRef, useState } from "react";
import * as React from "react";
import { generateTransactionID } from "../../library/utilities";
import { UserProfileData } from "../../library/constants";
import { useNavigate, useParams } from "react-router-dom";
import countries from "../../library/countries.json";

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const CreateStationForm = ({ action = "create" }) => {
    const navigator = useNavigate();
    const params = useParams();
    const stationID = params["stationID"];
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        postcode: "",
        country: ""
    });

    const data = {
        limit: 40,
        offset: 0,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    React.useEffect(() => {
        axios
            .post(getStationsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);

                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                if (action === "edit") {
                    const newCustomers = response["data"]["data"]["data"]?.find(
                        (item) => item.id === parseInt(stationID)
                    );

                    setFormData({
                        contact_name: newCustomers?.fullname,
                        contact_phone: newCustomers?.phone,
                        contact_email: newCustomers?.email,
                        address_1: newCustomers?.address,
                        address_2: newCustomers?.address_2,
                        city: newCustomers?.city_name,
                        state: newCustomers?.state,
                        postcode: newCustomers?.postal_code,
                        country: newCustomers?.country_code
                    })
                }
            });
    }, []);

    const form = useRef(null);

    const onSelectSenderCountry = (code) => {
        setFormData({
            ...formData,
            country: code
        })
    };

    const [isCreatingStation, setIsCreatingStation] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();


        const formValues = { ...formData };
        if (formData.country !== "") {
            formValues["country"] = countries[formData.country]
        }

        formValues["transaction_id"] = generateTransactionID(15);
        formValues["station_id"] = stationID;

        const newData = { ...formValues, ...UserProfileData() };

        setIsCreatingStation(true);

        let urlAction = action === "create" ? createStationUrl : editStationUrl;

        axios
            .post(urlAction, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsCreatingStation(false);
                window.scrollTo(0, 0);

                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage(`Successfully ${action==="edit"? "updated" : "created"} shipment. Redirecting now...`);

                    const shipmentID = response['data']["data"]['shipment-id'];
                    console.log(shipmentID);

                    setTimeout(() => {
                        setSuccessVisibility(false);

                        navigator("/stations");
                    }, 3000);
                } else {
                    const message = response['data']['message'];

                    setErrorVisibility(true);
                    setErrorMessage(message);
                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 3000);
                }
            });
    };

    const handleInput = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        })
    };

    return (
        <div>
            {
                isError === false ? null :
                    (<ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>)
            }

            {
                isSuccess === false ? null :
                    (<SuccessMessageContainer>
                        {successMessage}
                    </SuccessMessageContainer>)
            }

            {
                !isLoading ? (
                    <form ref={form} onSubmit={handleSubmit}>
                        <section>
                            <div className="d-flex align-items-center mb-2">
                                <SteppedNumber>1</SteppedNumber>
                                <SteppedTitle className="mx-2">Contact Details</SteppedTitle>
                            </div>
                            <Row className="form-row">
                                <Col className="form-group col-xl-5 col-md-6 col-12 mb-3 mb-xl-0">
                                    <label htmlFor="Full name">Contact Name</label>
                                    <input name="contact_name" onChange={handleInput}
                                        value={formData.contact_name} className="form-control" type="text" />
                                </Col>

                                <Col style={{ position: "relative" }} className="mb-3 mb-xl-0">
                                    <label htmlFor="Phone number">Phone Number</label>
                                    <input name="contact_phone" onChange={handleInput}
                                        value={formData.contact_phone} className="form-control" type="tel" />
                                </Col>

                                <Col className="form-group col-12 col-xl-3 col-md-6 mb-3 mb-xl-0">
                                    <label htmlFor="Email Address">Email Address</label>
                                    <input className="form-control" name="contact_email" onChange={handleInput} value={formData.contact_email} type="email" />
                                </Col>
                            </Row>

                        </section>

                        <div>
                            <section className="mt-5">
                                <div className="d-flex align-items-center mb-2">
                                    <SteppedNumber>2</SteppedNumber>
                                    <SteppedTitle className="mx-2">Location Details</SteppedTitle>
                                </div>
                                <Row className="form-row mt-3">
                                    <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                                        <label htmlFor="Sender Country">Country</label>
                                        <ReactFlagsSelect
                                            className="countries-text-field"
                                            selected={formData.country}
                                            onSelect={onSelectSenderCountry}
                                        // countries={["fi", "NG"]}
                                        />
                                    </Col>

                                    <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                                        <label htmlFor="State">State</label>
                                        <input onChange={handleInput} value={formData.state} name="state" className="form-control" type="text" />
                                    </Col>

                                    <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                                        <label htmlFor="Sender City">City</label>
                                        <input onChange={handleInput} name="city" value={formData.city} className="form-control" type="text" />
                                    </Col>

                                    <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                                        <label htmlFor="Sender Postal">Postal Code</label>
                                        <input onChange={handleInput}
                                            name="postcode"
                                            className="form-control"
                                            type="text"
                                            value={formData.postcode}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-row mt-3">
                                    <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                                        <label htmlFor="Address">Address</label>
                                        <input onChange={handleInput} value={formData.address_1}
                                            name="address_1" className="form-control" type="text" maxLength={45} />
                                    </Col>

                                    <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                                        <label htmlFor="Address">Address 2 (Optional)</label>
                                        <input onChange={handleInput} value={formData.address_2}
                                            name="address_2"
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            maxLength={45}
                                        />
                                    </Col>
                                </Row>
                            </section>

                            <div className="d-flex flex-row my-5 justify-content-end">
                                <SubmitButton type="submit" className="confirm-button">
                                    {isCreatingStation === false ? (
                                        <span className="button-text">{
                                            action === "edit" ? "EDIT" : "CREATE"
                                        }{" "} STATION</span>
                                    ) : (
                                        <span className="spinner-border spinner-grow-sm" />
                                    )}
                                </SubmitButton>
                            </div>
                        </div>

                    </form>
                ) : (
                    <LoadingContainer>
                        <LoadingSpinner className="spinner-border spinner-grow-sm" />
                    </LoadingContainer>
                )
            }
        </div>
    );
};

export default CreateStationForm;
