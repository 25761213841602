// const domain = "https://parcelflow-admin-backend-98c90d7360f6.herokuapp.com/engine/v1/actions/";
// const domain = "https://admin-parcelflow-backend-f2b0ceeea582.herokuapp.com/engine/v1/actions/";
// const domain = "https://admin.parcelflow.io/engine/v1/actions/";
const domain = "https://parcelflow-admin-767a056c40df.herokuapp.com/engine/v1/actions/"
// const domain = "https://parcelflow-admin-staging-f10dbf53d922.herokuapp.com/engine/v1/actions/"

export const loginURL = domain + "login.php";
export const registerURL = domain + "register.php";
export const logoutURL = domain + "logout";
export const requestOTPURL = domain + "send-verification-code.php";
export const verifyOTPURL = domain + "create-account";
export const updateOperatorURL = domain + "update-operator-type.php";
export const createInternationalShipmentURL = domain + "create-inter-shipment.php";
export const createInternationalShipmentNewURL = domain + "create-inter-shipment-new.php";
export const createTerminalInterShipment = domain + "create-inter-shipment-terminal.php";
export const getDashboardStatisticsURL = domain + "get-dashboard-stats";
export const getTransactionsURL = domain + "get-transactions";
export const getShipmentsURL = domain + "get-shipments-new-1";
export const getShipmentReportURL = domain + "get-report-shipments.php";
export const getCustomersURL = domain + "get-all-users";
export const getShipmentTimelineURL = domain + "get-shipment-timeline";
export const getShipmentPath = domain + "get-shipment-path";
export const getShipmentDetailsURL = domain + "get-shipment-by-shipment-id";
export const getRecentTransporterActivitiesURL = domain + "get-rider-activities";
export const getAllTransporterActivitiesURL = domain + "get-all-riders-activities";
export const getAllUsersActivitiesURL = domain + "get-all-users-activities";
export const getCompanyWalletDetails = domain + "get-virtual-account";
export const createCompanyWalletDetails = domain + "create-virtual-account";
export const approveShipmentURL = domain + "approve-international-shipment.php";
export const getCustomerDetailsURL = domain + "get-user-by-user-id";
export const makeContractUserURL = domain + "make-contract-user";
export const makeContractUser2URL = domain + "make-contract-user-2";
export const makeRegularUserURL = domain + "make-regular-user";
export const getShipmentsByUserURL = domain + "get-shipments-by-user-id";
export const searchForCustomersURL = domain + "search-for-users";
export const getContractDetailsUserURL = domain + "get-contract-details-by-user";
export const getDHLPickupLocationsURL = domain + "get-pickup-details";
export const getPackagingURL = domain + "get-packaging";
export const calculateDHLURL = domain + "calculate-shipment";
export const calculateTerminalURL = domain + "calculate-terminal-shipment";
export const getAllAdminsURL = domain + "get-all-employees";
export const cancelShipmentURL = domain + "cancel-shipment";
export const updateShipmentStatusURL = domain + "update-shipment-status";
export const getAddressSuggestionsURL = domain + "get-address-suggestions";
export const calculateLocalCostURL = domain + "calculate-shipment-cost";
export const createLocalShipmentURL = domain + "create-shipment";
export const getAllTransportersURL = domain + "get-all-riders";
export const rerouteShipmentURL = domain + "reroute-shipment";
export const routeShipmentURL = domain + "route-shipment";
export const topupUserWalletURL = domain + "topup-user-wallet";
export const deductUserWalletURL = domain + "deduct-wallet-balance";
export const markShipmentAsPaidURL = domain + "mark-shipment-as-paid";
export const getCustomerWalletHistoryURL = domain + "get-wallet-history";
export const getCustomerWalletDetailsURL = domain + "get-wallet-balance";
export const getAdminDetailsURL = domain + "get-employee-by-employee-id";
export const getCustomerStatsURL = domain + "get-user-stats";
export const getCustomerReferredUsersURL = domain + "get-user-referred-users";
export const getCustomerReferralDetailsURL = domain + "get-user-referral-details";
export const getTransactionDetailsURL = domain + "get-transaction-details";
export const getUnseenNotificationsURL = domain + "get-unseen-notifications";
export const getNotificationsURL = domain + "get-notifications";
export const markNotificationsAsReadURL = domain + "mark-notifications-as-read";
export const getUserReportsURL = domain + "get-user-reports";
export const getTransporterReportsURL = domain + "get-transporter-reports";
export const createAdminURL = domain + "create-employee";
export const uploadImageURL = "https://user.parcelflow.site/engine/v1/actions/upload-profile-image";
export const createTransporterURL = domain + "create-rider";
export const editAdminPrivilegesURL = domain + "edit-employee-privileges";
export const getAdminActivitiesURL = domain + "get-employee-activities";
export const getAdminPrivilegesURL = domain + "get-employee-privileges";
export const getShipmentPricingURL = domain + "get-price-settings";
export const changePasswordURL = domain + "change-password";
export const updateProfileURL = domain + "update-profile";
export const deleteEmployeeURL = domain + "delete-employee";
export const requestResetCodeURL = domain + "request-reset-password-code";
export const resetPasswordURL = domain + "reset-password";
export const getWhatsappChatListURL = domain + "get-whatsapp-chat-list";
export const sendWhatsappMessageURL = domain + "send-whatsapp-message";
export const getConversationMessagesURL = domain + "get-whatsapp-conversation-messages";
export const validateAddressURL = domain + "validate-address";
export const getCompanySettingsURL = domain + "get-company-settings";
export const getStatesURL = domain + "get-states";
export const getCitiesURL = domain + "get-cities";
export const updateAccountSettingsURL = domain + "update-account-settings";
export const getCompanySettings1URL = domain + "get-company-settings1";
export const searchDHLCityURL = domain + "search-dhl-cities";
export const getAllCustomerWalletURL = domain + "get-all-users-wallet";
export const searchForShipmentsURL = domain + "search-simple-for-shipments";
export const getInterstate3PLPricingURL = domain + "get-interstate-3pl-pricing";
export const updateInterstate3PLPricingURL = domain + "set-interstate-3pl-pricing";
export const getInternational3PLPricingURL = domain + "get-international-3pl-pricing";
export const updateInternational3PLPricingURL = domain + "set-international-3pl-pricing";
export const getStationsURL = domain + "get-stations";
export const getTransporterDetailsURL = domain + "get-rider-by-rider-id";
export const deleteTransporterURL = domain + "delete-rider";
export const deactivateTransporterURL = domain + "suspend-rider";
export const activateTransporterURL = domain + "activate-rider";
export const editTransporterURL = domain + "edit-transporter-details";
export const resetTransporterPasswordURL = domain + "reset-rider-password";
export const getAramexAccountURL = domain + "get-aramex-account";
export const getAllMarketersURL = domain + "get-all-marketers";
export const createMarketerURL = domain + "create-marketer";
export const getWhatsappTemplatesURL = domain + "get-whatsapp-templates";
export const createWhatsappTemplatesURL = domain + "create-whatsapp-template";
export const deleteWhatsappTemplatesURL = domain + "delete-whatsapp-template";
export const createWhatsappAccountURL = domain + "create-whatsapp-account";
export const registerWhatsappPhoneURL = domain + "register-whatsapp-phone-number";
export const getWhatsappNumbersURL = domain + "get-whatsapp-phone-numbers";
export const getShipmentPackagesURL = domain + "get-shipment-packages";
export const getPendingKycUrl = domain + "get-pending-kycs.php";
export const approveKycUrl = domain + "approve-kyc.php";
export const rejectKycUrl = domain + "reject-kyc.php";
export const getWalletReportUrl = domain + "create-wallet-report.php";
export const getCustomerKYCDocUrl = domain + "get-customer-kyc.php";
export const activateUserUrl = domain + "activate-user";
export const deactivateUserUrl = domain + "deactivate-user";
export const suspendUserUrl = domain + "suspend-user";
export const unsuspendUserUrl = domain + "unsuspend-user";
export const createStationUrl = domain + "create-station";
export const editStationUrl = domain + "edit-station";
export const deleteStationUrl = domain + "delete-station";
export const updateShipmentSettingsUrl = domain + "update-shipment-settings";
export const getShipmentSettingsUrl = domain + "get-shipment-settings-new.php";
export const advanceShipmentSearchUrl = domain + "search-advance-for-shipments";
export const addShipmentsToBatchUrl = domain + "add-shipments-to-batch";
export const getAllBatchUrl = domain + "get-all-batches";
export const searchBatchUrl = domain + "search-batch-numbers";
export const getBatchShipmentsUrl = domain + "get-all-batch-shipments";
export const uploadMediaUrl = "https://user.parcelflow.site/engine/v1/actions/upload-profile-image";
export const updateAppInfoUrl = domain + "update-app-info.php";
export const getAppInfoUrl = domain + "get-app-info";
export const getShipmentLevelsUrl = domain + "get-shipment-levels";
export const addPackagingUrl = domain + "add-packaging";
export const editPackagingUrl = domain + "edit-packaging";
export const deletePackagingUrl = domain + "delete-packaging";
export const editAdminUrl = domain + "edit-employee-details";
export const updatePriceSettingsUrl = domain + "update-price-settings";
export const updatePaystackDetailsUrl = domain + "update-paystack-details";
export const getPaystackDetailsUrl = domain + "get-paystack-details";
export const getNotificationSettingsUrl = domain + "get-notification-settings";
export const setNotificationSettingsUrl = domain + "set-notification-setting";
export const massUpdateShipmentStatusUrl = domain + "mass-update-status";
export const calculatePickupCostUrl = "https://parcelflow-user-b4e5130303b2.herokuapp.com/engine/v1/actions/calculate-pickup-cost";
export const generateInvoiceUrl = "https://parcelflow-user-b4e5130303b2.herokuapp.com/engine/v1/actions/create-shipment-invoice";
export const getAdditionalServiceUrl = domain + "get-additional-services";
export const getShipmentServicesUrl = domain + "get-shipment-additional-services";
export const uploadPackageImgUrl = "https://admin.parcelflow.site/engine/v1/actions/upload-package-image";
export const getCompanyInsuranceUrl = domain + "get-company-insurance";
export const calculateDiscrepancyCostUrl = domain + "get-company-insurance";
export const updateShipmentInfoUrl = domain + "update-shipment-info";
export const updateWaybillInfoUrl = domain + "update-waybill-info";
export const uploadWaybillURL = "https://admin.parcelflow.site/engine/v1/actions/upload-waybill";
export const getSalesReportsUrl = domain + "calculate-shipment-profit";