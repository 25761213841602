import {Col, Row} from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {SessionManagement} from "../../../library/SessionManagement";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import ChangePasswordModal from "../../../modals/ChangePasswordModal";
import {generateTransactionID} from "../../../library/utilities";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {createInternationalShipmentURL, updateProfileURL} from "../../../library/URLs";
import CompanyDetails from "./CompanyDetails";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
    margin-top:20px;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
    margin-top:20px;
`;

const PersonalDetails = () => {
    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [isUpdating, setIsUpdating] = useState(false);

    const [changePasswordDialogVisibility, setChangePasswordDialogVisibility] = useState(false);

    const firstName = SessionManagement.getFirstName();
    const lastName = SessionManagement.getLastName();
    const phoneNumber = SessionManagement.getPhone();
    const emailAddress = SessionManagement.getEmail();
    const profileImageURL = SessionManagement.getProfileImageURL();

    // useEffect(() => {
    //     const objectUrl = URL.createObjectURL(selectedFile)
    //     setPreview(objectUrl)
    //
    //     // free memory when ever this component is unmounted
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [selectedFile])

    const handleCloseModal = () => {
        setChangePasswordDialogVisibility(false);
    };

    const showChangePasswordDialog = () => {
        setChangePasswordDialogVisibility(true);
    }

    const form = useRef(null);

    const updateProfile = (e) => {
        e.preventDefault();

        setErrorVisibility(false);

        const data = new FormData(form.current);

        const formValues = {};

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setIsUpdating(true);

        axios
            .post(updateProfileURL, newData)
            .then(function (response) {
                console.log(response);
                setIsUpdating(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    window.scrollTo(0, 0);

                    const firstName = response["data"]["data"]["first_name"];
                    const lastName = response["data"]["data"]["last_name"];
                    const email = response["data"]["data"]["email"];
                    const phoneNumber = response["data"]["data"]["phone"];

                    console.log(phoneNumber);

                    SessionManagement.setFirstName(firstName);
                    SessionManagement.setLastName(lastName);
                    SessionManagement.setEmail(email);
                    SessionManagement.setPhone(phoneNumber);

                    setSuccessVisibility(true);
                    setSuccessMessage(
                        "Profile has been updated successfully."
                    );

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                return error;
            });
    }

    return (
        <>
            {
                isError === false ? null :
                    (<ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>)
            }

            {
                isSuccess=== false ? null :
                    (<SuccessMessageContainer>
                        {successMessage}
                    </SuccessMessageContainer>)
            }

            <ChangePasswordModal
                isModalVisible={changePasswordDialogVisibility}
                handleCloseDialog={handleCloseModal}
            />

            <p style={{fontSize:22, marginTop:30, marginBottom:0, fontWeight:"bold"}}>Personal Details</p>
            <span>Edit your personal details</span>
            <form ref={form} onSubmit={updateProfile}>
                <div className="row mt-4">
                    <div className="col-xl-8 col-md-6 col-12 col">
                        <Row>
                            <Col className="form-group col-12 col-xl-6 col-md-6">
                                <label htmlFor="Full Name">First Name</label>
                                <input name="first_name" className="form-control" type="text" defaultValue={firstName} />
                            </Col>

                            <Col className="form-group col-12 col-xl-6 col-md-6">
                                <label htmlFor="Email">Last Name</label>
                                <input name="last_name" className="form-control" type="text" placeholder="" defaultValue={lastName} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="form-group col-12 mb-3 mb-md-0 col-xl-6 col-md-6">
                                <label htmlFor="Email Address">Email Address</label>
                                <input name="email" className="form-control" type="text" defaultValue={emailAddress} />
                            </Col>

                            <Col className="form-group col-12 col-xl-6 col-md-6">
                                <label htmlFor="Email">Phone Number</label>
                                <input name="phone_number" className="form-control" type="text" placeholder="" defaultValue={phoneNumber}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="form-group col-12 col-xl-6 col-md-6">
                                <label htmlFor="Full Name">Change Password</label>
                                <div onClick={(e) => showChangePasswordDialog()} className="change-password-button">
                                    Change Password
                                </div>
                            </Col>
                        </Row>


                    </div>
                    <div className="col-xl-4 col-md-6 mt-4 mt-md-0 col-12 col">
                        <div className="d-flex flex-column align-items-center">
                            <img style={{width:65, height:65, borderRadius:"50%"}}
                                 src={profileImageURL}/>
                            <span style={{marginTop:15, fontSize:19}}>Change Profile Picture</span>
                            <div style={{marginTop:20}} className="change-password-button">Choose Image</div>
                        </div>
                    </div>

                    <div className="d-flex flex-row my-5 justify-content-end">
                        <SubmitButton type="submit" className="confirm-button">
                            {isUpdating === false ? (
                                <span className="button-text">UPDATE PROFILE</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )}
                        </SubmitButton>
                    </div>
                </div>
            </form>
            {
                SessionManagement.getEmployeeType() ===
                "Super Admin" && (
                    <div style={{ borderTop: "1px dashed #BFC9CA", paddingTop: "3rem" }} >
                        <CompanyDetails />
                    </div>
                )
            }
        </>
    );
}

export default PersonalDetails;