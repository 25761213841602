import {Col, Modal, Row} from "react-bootstrap";
import {Form} from "react-final-form";
import styled from "styled-components";
import axios from "axios";
import "../../styles/create-local-shipment.scss";
import {
    calculateLocalCostURL,
    createLocalShipmentURL,
    getAddressSuggestionsURL, getAllTransportersURL,
    getCustomersURL, getDHLPickupLocationsURL, searchForCustomersURL
} from "../../library/URLs";
import {useEffect, useRef, useState} from "react";
import * as React from "react";
import {generateTransactionID} from "../../library/utilities";
import {UserProfileData} from "../../library/constants";
import {useNavigate} from "react-router-dom";
import {BsDot} from "react-icons/bs";

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const CreateLocalShipmentForm = () => {
    const navigator = useNavigate();
    const form = useRef(null);

    const [pickupLocation, setPickupLocation] = useState("");
    const [dropoffLocation, setDropoffLocation] = useState("");

    const [detailedAddressUsageChecked, setDetailedAddressUsageChecked] = useState(false);

    const [pickupAddressSuggestions, setPickupAddressSuggestions] = useState([]);
    const [dropoffAddressSuggestions, setDropoffAddressSuggestions] = useState([]);

    const [shipmentPrice, setShipmentPrice] = useState("0");

    const [showDropoffSuggestions, setShowDropoffSuggestions] = useState(false);
    const [showPickupSuggestions, setShowPickupSuggestions] = useState(false);

    const [isShipmentCalculated, setIsShipmentCalculated] = useState(true);
    const [showPhase2Shipment, setShowPhase2Shipment] = useState(true);

    const [isCalculatingShipment, setIsCalculatingShipment] = useState(false);
    const [isCreatingShipment, setIsCreatingShipment] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [transporters, setTransporters] = useState([]);

    const [senderName, setSenderName] = useState("");
    const [senderEmailAddress, setSenderEmailAddress] = useState("");
    const [senderPhoneNumber, setSenderPhoneNumber] = useState("");

    const [showSenderSuggestions, setShowSenderSuggestions] = useState(false);
    const [senderSuggestions, setSenderSuggestions] = useState([]);

    const toggleDetailedAddressUsage = () => {
        setDetailedAddressUsageChecked(!detailedAddressUsageChecked);
    }

    useEffect(() => {
        console.log(UserProfileData());
        axios
            .post(getAllTransportersURL, UserProfileData())
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    const transporters = response["data"]["data"];
                    console.log(transporters);

                    setTransporters(transporters);
                }
            })
            .catch(function (error) {
                return error;
            });
    }, [])

    const selectPlace = (location, type) => {
        if (type === "Pickup") {
            setPickupLocation(location);
            setShowPickupSuggestions(false);
        } else {
            setDropoffLocation(location);
            setShowDropoffSuggestions(false);
        }
    }

    const suggestPlaces = (event, type) => {
        const keyword = event.target.value;

        const newData = {
            "keyword": keyword,
            "company_id": UserProfileData().company_id
        };

        if (type === "Pickup") {
            setPickupLocation(keyword);
            setShowPickupSuggestions(true);
        } else {
            setDropoffLocation(keyword);
            setShowDropoffSuggestions(true);
        }

        axios
            .post(getAddressSuggestionsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                const predictions = response["data"]["predictions"];

                if (type === "Pickup") {
                    setPickupAddressSuggestions(predictions);
                } else {
                    setDropoffAddressSuggestions(predictions);
                }

                console.log(predictions);
            });
    }

    const calculateCost = () => {
        const data = new FormData(form.current);

        const formValues = {};

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        formValues["transaction_id"] = generateTransactionID(15);

        const newData = {...formValues, ...UserProfileData()};

        setIsCalculatingShipment(true);

        axios
            .post(calculateLocalCostURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsCalculatingShipment(false);
                if (response['data']['success'] === true) {
                    const amount = response["data"]["data"]["amount"];

                    setShipmentPrice(amount);
                    setIsShipmentCalculated(true);
                } else {
                    const message = response['data']['message'];

                    setErrorVisibility(true);
                    setErrorMessage(message);
                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 3000);
                }
            });
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleContinueShipment = () => {
        setShowPhase2Shipment(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(form.current);

        const formValues = {};

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        formValues["transaction_id"] = generateTransactionID(15);

        const newData = {...formValues, ...UserProfileData()};

        setIsCreatingShipment(true);

        axios
            .post(createLocalShipmentURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsCreatingShipment(false);
                window.scrollTo(0, 0);

                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Successfully created shipment. Redirecting now...");

                    const shipmentID = response['data']["data"]['shipment-id'];
                    console.log(shipmentID);

                    setTimeout(() => {
                        setSuccessVisibility(false);

                        navigator("/shipment/" + shipmentID);
                    }, 3000);
                } else {
                    const message = response['data']['message'];

                    setErrorVisibility(true);
                    setErrorMessage(message);
                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 3000);
                }
            });
    };

    const selectSender = (customerName, customerNumber, emailAddress) => {
        setSenderName(customerName);
        setSenderEmailAddress(emailAddress);
        setSenderPhoneNumber(customerNumber);

        setShowSenderSuggestions(false);
    }

    const onChangeSenderDetails = (event, type) => {
        if (type === "senderPhoneNumber") {
            setSenderPhoneNumber(event.target.value);
        } else if (type === "senderName") {
            setSenderName(event.target.value);
            searchForCustomers(event);
        }

        // setIsShipmentCalculated(false);
        // setShowPhase2Shipment(false);
    }

    const searchForCustomers = (e) => {
        const query = e.target.value;

        if (query.toString().trim() === "") {
            return null;
        }

        const formValues = {}
        formValues["query"] = query;
        formValues["limit"] = "5";

        const newData = {...formValues, ...UserProfileData()};

        console.log(newData);

        axios
            .post(searchForCustomersURL, newData)
            .then(function (response) {
                console.log(response);

                if (response["data"]["success"] === false) return;

                const customers = response["data"]["data"];

                setShowSenderSuggestions(true);
                setSenderSuggestions(customers);
            });
    }

    return (
        <div>
            {
                isError === false ? null :
                    (<ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>)
            }

            {
                isSuccess === false ? null :
                    (<SuccessMessageContainer>
                        {successMessage}
                    </SuccessMessageContainer>)
            }

            <form ref={form} onSubmit={handleSubmit}>
                <section>
                    <div className="d-flex align-items-center mb-2">
                        <SteppedNumber>1</SteppedNumber>
                        <SteppedTitle className="mx-2">Location Details</SteppedTitle>
                    </div>
                    <Row className="form-row">
                        <Col className="form-group col-xl-5 col-md-6 col-12 mb-3 mb-xl-0">
                            <label htmlFor="Pickup Location">Pickup Location</label>
                            <input name="pick_up_location" onChange={(e) => suggestPlaces(e, "Pickup")}
                                   value={pickupLocation} className="form-control" type="text"/>
                            <div style={{width: "38%"}}
                                 className={`suggestions-field ${showPickupSuggestions ? "suggestions-field-visible" : null}`}>
                                {
                                    pickupAddressSuggestions?.map((suggestions) => {
                                        const mainText = suggestions.structured_formatting.main_text;
                                        const description = suggestions.structured_formatting.secondary_text;
                                        return (
                                            <div onClick={(e) => selectPlace(mainText, "Pickup")}
                                                 key={suggestions.place_id} className="single-suggestion">
                                                <span>{mainText}</span>
                                                <span style={{fontSize: 13, marginTop: -5}}>{description}</span>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </Col>

                        <Col style={{position: "relative"}} className="mb-3 mb-xl-0">
                            <label htmlFor="Full Name">Dropoff Location</label>
                            <input name="drop_off_locations" onChange={(e) => suggestPlaces(e, "Dropoff")}
                                   value={dropoffLocation} className="form-control" type="text"/>
                            <div style={{width: "95%"}}
                                 className={`suggestions-field ${showDropoffSuggestions ? "suggestions-field-visible" : null}`}>
                                {
                                    dropoffAddressSuggestions?.map((suggestions) => {
                                        const mainText = suggestions.structured_formatting.main_text;
                                        const description = suggestions.structured_formatting.secondary_text;
                                        return (
                                            <div onClick={(e) => selectPlace(mainText, "dropoff")}
                                                 key={suggestions.place_id} className="single-suggestion">
                                                <span>{mainText}</span>
                                                <span style={{fontSize: 13, marginTop: -5}}>{description}</span>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </Col>

                        <Col className="form-group col-12 col-xl-2 col-md-6 mb-3 mb-xl-0">
                            <label htmlFor="Phone Number">Price</label>
                            {/*<input className="form-control" type="text" placeholder="" value={"₦" + numberWithCommas(shipmentPrice)}*/}
                            {/*       disabled="disabled"/>*/}
                            {/*<input name="amount" type="hidden" value={shipmentPrice}/>*/}
                            <input className="form-control" name="amount" type="number"/>
                        </Col>
                    </Row>

                    {isShipmentCalculated === false && isCalculatingShipment === false ? (
                        <div className="d-flex justify-content-end mt-5">
                            <SubmitButton onClick={calculateCost} type="button" className="confirm-button">
                                <span className="button-text">CALCULATE COST</span>
                            </SubmitButton>
                        </div>
                    ) : isShipmentCalculated === true ? null : (
                        <div className="d-flex justify-content-end mt-5">
                            <SubmitButton onClick={calculateCost} type="button" className="confirm-button">
                                <span className="spinner-border spinner-grow-sm"/>
                            </SubmitButton>
                        </div>
                    )}

                    {
                        detailedAddressUsageChecked === false ? null : (
                            <Row className="mt-3">
                                <Col className="form-group col-12 col-xl-6 col-md-6 mb-3 mb-xl-0">
                                    <label htmlFor="Phone Number">Detailed Pickup Address</label>
                                    <input name="detailed_pickup_address" className="form-control" type="text"
                                           placeholder=""/>
                                </Col>
                                <Col className="form-group col-12 col-xl-6 col-md-6 mb-3 mb-xl-0">
                                    <label htmlFor="Phone Number">Detailed Dropoff Address</label>
                                    <input name="detailed_dropoff_address" className="form-control" type="text"
                                           placeholder=""/>
                                </Col>
                            </Row>
                        )
                    }

                    {isShipmentCalculated === true && showPhase2Shipment === false ? (
                        <div className="mt-3">
                            <div className="d-flex justify-content-sm-between mt-5">
                                <div className="local-shipment-form form-check">
                                    <input checked={detailedAddressUsageChecked} onChange={toggleDetailedAddressUsage}
                                           className="form-check-input" type="checkbox" value="" id="localShipment"/>
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Use Detailed Address
                                    </label>
                                </div>

                                <SubmitButton type="button" className="confirm-button">
                                    <span onClick={handleContinueShipment} className="button-text">CONTINUE</span>
                                </SubmitButton>
                            </div>
                        </div>
                    ) : null}
                </section>

                {
                    showPhase2Shipment === false ? null :
                        (<div>
                            <section className="mt-5">
                                <div className="d-flex align-items-center mb-2">
                                    <SteppedNumber>2</SteppedNumber>
                                    <SteppedTitle className="mx-2">Sender & Receiver Details</SteppedTitle>
                                </div>
                                <Row className="form-row">
                                    <Col className="form-group col-xl-3 col-12 col-md-6 mb-3 mb-xl-0">
                                        <label htmlFor="Pickup Location">Sender Name</label>
                                        <input onChange={(e) => onChangeSenderDetails(e, "senderName")}
                                               name="sender_name" value={senderName} className="form-control"
                                               type="text"/>
                                        <div style={{width: "22%"}}
                                             className={`suggestions-field ${showSenderSuggestions ? "suggestions-field-visible" : null}`}>
                                            {
                                                senderSuggestions.map((suggestions) => {
                                                    const fullName = suggestions.fullname;
                                                    const phoneNumber = suggestions.phone;
                                                    const emailAddress = suggestions.email;
                                                    return (
                                                        <div
                                                            onClick={(e) => selectSender(fullName, phoneNumber, emailAddress)}
                                                            key={suggestions.id} className="single-suggestion">
                                                            <span>{fullName}</span>
                                                            <div className="d-flex flex-row align-items-center">
                                                                <span style={{
                                                                    fontSize: 13,
                                                                    marginTop: -5
                                                                }}>{phoneNumber}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </Col>

                                    <Col className="form-group col-12 col-xl-3 col-md-6 mb-3 mb-xl-0">
                                        <label htmlFor="Pickup Location">Sender Number</label>
                                        <input onChange={(e) => onChangeSenderDetails(e, "senderPhoneNumber")}
                                               value={senderPhoneNumber}
                                               name="sender_phone_no" className="form-control" type="text"/>
                                    </Col>

                                    <Col className="form-group col-12 col-xl-3 col-md-6 mb-3 mb-xl-0">
                                        <label htmlFor="Full Name">Receiver Name</label>
                                        <input name="recipient_name" className="form-control" type="text"/>
                                    </Col>

                                    <Col className="form-group col-12 col-xl-3 col-md-6 mb-3 mb-xl-0">
                                        <label htmlFor="Phone Number">Receiver Number</label>
                                        <input name="recipient_phone_no" className="form-control" type="text"
                                               placeholder=""/>
                                    </Col>
                                </Row>
                            </section>

                            <section className="mt-5">
                                <div className="d-flex align-items-center mb-2">
                                    <SteppedNumber>3</SteppedNumber>
                                    <SteppedTitle className="mx-2">Shipment Details</SteppedTitle>
                                </div>
                                <Row className="form-row">
                                    <Col className="form-group col-xl-6 col-12 col-md-6 mb-3 mb-xl-0">
                                        <label htmlFor="Full Name">Content</label>
                                        <input name="item_name" className="form-control" type="text"/>
                                    </Col>

                                    <Col className="form-group col-xl-3 col-12 col-md-6 mb-3 mb-xl-0">
                                        <label htmlFor="Length">Payment Method</label>
                                        <select name="payment_method" className="form-select">
                                            <option value="">Select Payment Method</option>
                                            <option value="Cash On Pickup">Cash On Pickup</option>
                                            <option value="Cash On Delivery">Cash On Delivery</option>
                                            <option value="Card Payment">Card Payment</option>
                                            <option value="Direct Transfer">Direct Transfer</option>
                                            <option value="Wallet">Wallet</option>
                                        </select>
                                    </Col>

                                    <Col className="form-group col-xl-3 col-12 col-md-6 mb-3 mb-xl-0">
                                        <label htmlFor="Width">Transporter</label>
                                        <select name="rider_id" className="form-select">
                                            <option value="">Choose Transporter</option>
                                            {transporters.map((transporter) => {
                                                return (<option key={transporter.id}
                                                                value={transporter.id}>{transporter.name}</option>);
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </section>

                            <div className="d-flex flex-row my-5 justify-content-end">
                                <SubmitButton type="submit" className="confirm-button" disabled={isCreatingShipment}>
                                    {isCreatingShipment === false ? (
                                        <span className="button-text">PROCESS SHIPMENT</span>
                                    ) : (
                                        <span className="spinner-border spinner-grow-sm"/>
                                    )}
                                </SubmitButton>
                            </div>
                        </div>)
                }

            </form>
        </div>
    );
};

export default CreateLocalShipmentForm;
