import revenueIcon from "../../images/revenue.jpg";
import packageIcon from "../../images/package.jpg";
import cardIcon from "../../images/card.png";
import userIcon from "../../images/user.png";

const OverallStatistics = ({totalRevenue, totalShipments, totalCustomers, totalTransactions}) => {
  return (
    <div className="row g-2">
      <div className="col-md-6 col-xl-3">
        <div className="card bg-light border-0 shadow">
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <h4 className="title">₦{totalRevenue}</h4>
                <span className="subtitle">Total Revenue</span>
              </div>
              <img className="icon" src={revenueIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xl-3">
        <div className="card bg-light border-0 shadow">
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <h4 className="title">{totalShipments}</h4>
                <span className="subtitle">Shipments</span>
              </div>
              <img className="icon" src={packageIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xl-3">
        <div className="card bg-light border-0 shadow">
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <h4 className="title">{totalCustomers}</h4>
                <span className="subtitle">Customers</span>
              </div>
              <img className="icon" src={userIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xl-3">
        <div className="card bg-light border-0 shadow">
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <h4 className="title">{parseFloat(totalTransactions).toLocaleString("en")}</h4>
                <span className="subtitle">Transactions</span>
              </div>
              <img className="icon" src={cardIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallStatistics;
