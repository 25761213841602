import "./styles/home.scss";
import Dashboard from "./pages/Dashboard";
import Customers from "./pages/Customers";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Shipments from "./pages/Shipments";
import SingleShipment from "./pages/SingleShipment";
import CreateShipment from "./pages/CreateShipment";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import {PrivateRoute, PublicRoute} from "./pages/Auth/RouteManager";
import Accounts from "./pages/Accounts";
import SingleCustomer from "./pages/SingleCustomer";
import Admins from "./pages/Admins";
import Whatsapp from "./pages/Whatsapp/Whatsapp";
import SingleAdmin from "./pages/SingleAdmin";
import ShipmentReports from "./pages/Reports/ShipmentReports";
import ReportsHome from "./pages/Reports/ReportsHome";
import Transactions from "./pages/Transactions";
import SingleTransaction from "./pages/SingleTransaction";
import Notifications from "./pages/Notifications";
import CreateAdmin from "./pages/CreateAdmin";
import CustomerReports from "./pages/Reports/CustomerReports";
import TransporterReports from "./pages/Reports/TransporterReports";
import Partners from "./pages/Partners";
import SinglePartner from "./pages/SinglePartner";
import Transporters from "./pages/Transporter/Transporters";
import CreateTransporter from "./pages/Transporter/CreateTransporter";
import Settings from "./pages/Settings";
import ResetPassword from "./pages/Auth/ResetPassword";
import CustomersWallet from "./pages/CustomerWallet";
import CustomersWalletHistory from "./pages/CustomerWalletHistory";
import Stations from "./pages/Stations";
import SingleTransporter from "./pages/SingleTransporter";
import Marketers from "./pages/Marketers";
import CreateMarketer from "./pages/CreateMarketer";
import WhatsappTemplates from "./pages/Whatsapp/WhatsappTemplates";
import CreateWhatsappTemplate from "./pages/Whatsapp/CreateTemplate";
import WhatsappSettings from "./pages/Whatsapp/Settings";
import Verify from "./pages/Auth/Verify";
import UpdateOperator from "./pages/Auth/OperatorType";
import DuplicateShipment from "./pages/DuplicateShipment";
import KycApprovals from "./pages/PendingApprovals/KycApprovals";
import WalletReports from "./pages/Reports/WalletReports";
import CreateStation from "./pages/CreateStation";
import SingleStation from "./pages/SingleStation";
import EditStation from "./pages/EditStation";
import Batches from "./pages/Batches";
import SingleBatch from "./pages/SingleBatch";
import Packages from "./pages/Packages";
import EditAdmin from "./pages/EditAdmin";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SalesReports from "./pages/Reports/SalesReports";

function App() {
    return (
        <div>
            <ToastContainer />
            <BrowserRouter>
                <Routes>
                    <Route element={<PublicRoute/>}>
                        <Route path="login" element={<Login/>}/>
                        <Route path="register" element={<Register/>}/>
                        <Route path="forgot-password" element={<ForgotPassword/>}/>
                        <Route path="reset-password" element={<ResetPassword/>}/>
                        <Route path="verify-otp" element={<Verify/>}/>
                        <Route path="update-operator" element={<UpdateOperator />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                        <Route element={<Marketers/>} path="/marketers" />
                        <Route element={<Dashboard/>} path="/" exact />
                        <Route path="whatsapp">
                            <Route index element={<Whatsapp/>}/>
                            <Route path="messages" element={<Whatsapp />} />
                            <Route path="templates" element={<WhatsappTemplates />} />
                            <Route path="create-template" element={<CreateWhatsappTemplate />} />
                            <Route path="settings" element={<WhatsappSettings />} />
                        </Route>
                        <Route element={<Accounts/>} path="/account" />
                        <Route element={<Customers/>} path="/customers" />
                        {/* <Route element={<Stations/>} path="/stations" /> */}
                        <Route path="stations">
                            <Route index element={<Stations/>}/>
                            <Route path="create" element={<CreateStation/>}/>
                            <Route path=":stationID" element={<SingleStation/>}/>
                            <Route path="edit-station/:stationID" element={<EditStation/>}/>
                        </Route>
                        <Route element={<Transporters/>} path="/transporters" />
                        <Route element={<Notifications/>} path="/notifications" />
                        <Route element={<Settings/>} path="/settings" />
                        <Route path="customer">
                            <Route path=":customerID" element={<SingleCustomer/>}/>
                        </Route>
                        <Route element={<Transactions/>} path="/transactions" />
                        <Route element={<CustomersWallet/>} path="/customers-wallet" />
                        <Route path="customer-wallet-history">
                            <Route path=":customerID" element={<CustomersWalletHistory/>}/>
                        </Route>
                        <Route path="transaction">
                            <Route path=":transactionID" element={<SingleTransaction/>}/>
                        </Route>
                        <Route element={<Admins/>} path="/admins" />
                        <Route path="admin">
                            <Route path=":adminID" element={<SingleAdmin/>}/>
                            <Route path="edit/:adminID" element={<EditAdmin/>}/>
                        </Route>
                        <Route path="transporter">
                            <Route path=":transporterID" element={<SingleTransporter/>}/>
                            <Route element={<CreateTransporter/>} path="create-transporter" />
                            <Route element={<CreateTransporter/>} path="edit-transporter" />
                            <Route element={<CreateTransporter/>} path="reset-password" />
                        </Route>
                        <Route element={<CreateAdmin/>} path="/create-admin" />
                        <Route element={<CreateMarketer/>} path="/create-marketer" />
                        <Route element={<CreateShipment/>} path="/create-shipment" />
                        <Route path="shipments">
                            <Route path=":shipmentType" element={<Shipments/>}/>
                            <Route path="batches" element={<Batches/>}/>
                            <Route path="batches/:batchID" element={<SingleBatch/>}/>
                            <Route path="packages" element={<Packages/>} />
                        </Route>
                        <Route path="shipment">
                            <Route path=":shipmentID" element={<SingleShipment/>}/>
                        </Route>
                        <Route path="duplicate-shipment">
                            <Route path=":shipmentID" element={<DuplicateShipment/>}/>
                        </Route>
                        <Route element={<Partners/>} path="/partners" />
                        <Route path="partner">
                            <Route path=":partnerID" element={<SinglePartner/>}/>
                        </Route>
                        <Route path="reports">
                            <Route index element={<ReportsHome/>}/>
                            <Route path="shipments" element={<ShipmentReports />} />
                            <Route path="customers" element={<CustomerReports />} />
                            <Route path="transporters" element={<TransporterReports />} />
                            <Route path="wallets" element={<WalletReports />} />
                            <Route path="sales" element={<SalesReports />} />
                        </Route>
                        <Route element={<KycApprovals/>} path="/pending-kyc" />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
