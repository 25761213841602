import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
    updatePaystackDetailsUrl,
    getPaystackDetailsUrl,
} from "../../../library/URLs";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import PaystackPasswordModal from "../../../modals/PaystackPassword";
import { TbPencil, TbPencilOff } from "react-icons/tb";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const PaystackSettings = () => {
    const [paystackInfo, setPaystackInfo] = useState({
        paystack_public_key: "",
        paystack_secret_key: "",
    });
    const [showSecretKey, setShowSecretKey] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [isUpdating, setIsUpdating] = useState(false);

    const [isEditable, setIsEditable] = useState(false);

    const [changePasswordDialogVisibility, setChangePasswordDialogVisibility] =
        useState(false);

    useEffect(() => {
        getPaystackDetails();
        // eslint-disable-next-line
    }, []);

    const getPaystackDetails = () => {
        const newData = { ...UserProfileData() };

        console.log(newData);

        axios
            .post(getPaystackDetailsUrl, newData)
            .then(function (response) {
                if (response?.data?.success) {
                    let response_data = response?.data?.data;
                    setPaystackInfo({
                        ...paystackInfo,
                        paystack_public_key: response_data?.paystack_public_key,
                        paystack_secret_key: response_data?.paystack_secret_key,
                    });
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    const handleCloseModal = () => {
        setChangePasswordDialogVisibility(false);
    };

    const form = useRef(null);

    const updatePaystackDetails = (e) => {
        e.preventDefault();

        setErrorVisibility(false);

        const formValues = { ...paystackInfo };

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setIsUpdating(true);

        axios
            .post(updatePaystackDetailsUrl, newData)
            .then(function (response) {
                console.log(response);
                setIsUpdating(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    window.scrollTo(0, 0);

                    setSuccessVisibility(true);
                    setSuccessMessage("Paystack Keys updated successfully.");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    const handleInput = (e) => {
        const { name, value } = e.target;

        setPaystackInfo({
            ...paystackInfo,
            [name]: value,
        });
    };

    return (
        <>
            {isError === false ? null : (
                <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            )}

            {isSuccess === false ? null : (
                <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
            )}

            <PaystackPasswordModal
                isModalVisible={changePasswordDialogVisibility}
                handleCloseDialog={handleCloseModal}
                setIsEditable={setIsEditable}
            />

            <p
                style={{
                    fontSize: 22,
                    marginTop: 30,
                    marginBottom: 0,
                    fontWeight: "bold",
                }}
            >
                Paystack Settings
            </p>
            <span>Edit your company paystack keys to manage customer payments</span>
            <form ref={form}>
                <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-sm-12 col">
                        <Row className="mb-5">
                            <Col className="form-group col-xl-3 col-md-3">
                                <SubmitButton
                                    type="button"
                                    onClick={
                                        isEditable ? ()=>setIsEditable(false) :
                                        () => setChangePasswordDialogVisibility(!changePasswordDialogVisibility)
                                    }
                                    className="confirm-button"
                                >
                                    <span className="button-text">Edit{" "} {
                                        isEditable ? (
                                            <TbPencilOff />
                                        ) : (
                                            <TbPencil />
                                        )
                                    }</span>
                                </SubmitButton>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col className="form-group col-xl-12 col-md-12">
                                <label htmlFor="Business name">Public API Key</label>
                                <input
                                    name="paystack_public_key"
                                    className="form-control"
                                    type="text"
                                    value={paystackInfo.paystack_public_key}
                                    onChange={handleInput}
                                    placeholder="pk_live_*************"
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="form-group col-xl-12 col-md-12 position-relative">
                                <label htmlFor="Business name">Private API Key</label>
                                <input
                                    name="paystack_secret_key"
                                    className="form-control"
                                    type={showSecretKey ? "text" : "password"}
                                    value={paystackInfo.paystack_secret_key}
                                    onChange={handleInput}
                                    placeholder="sk_live_****************"
                                    disabled={!isEditable}
                                />
                                {showSecretKey ? (
                                    <BsEyeFill
                                        className="position-absolute"
                                        style={{ cursor: "pointer", right: "2rem", top: "55%" }}
                                        onClick={() => setShowSecretKey(!showSecretKey)}
                                    />
                                ) : (
                                    <BsEyeSlashFill
                                        className="position-absolute"
                                        style={{ cursor: "pointer", right: "2rem", top: "55%" }}
                                        onClick={() => setShowSecretKey(!showSecretKey)}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12 col"></div>

                    <div className="d-flex flex-row my-5 justify-content-end">
                        <SubmitButton
                            disabled={isUpdating}
                            type="submit"
                            onClick={updatePaystackDetails}
                            className="confirm-button"
                        >
                            {isUpdating === false ? (
                                <span className="button-text">UPDATE API KEYS</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm" />
                            )}
                        </SubmitButton>
                    </div>
                </div>
            </form>
        </>
    );
};

export default PaystackSettings;
