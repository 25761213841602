import { useState, useRef, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../../components/Notifications";
import {
  calculateDHLURL,
  createInternationalShipmentURL,
  getDHLPickupLocationsURL,
  getPackagingURL,
  searchForCustomersURL,
  getStationsURL,
  createInternationalShipmentNewURL,
  calculatePickupCostUrl,
  getAdditionalServiceUrl,
} from "../../../library/URLs";
import styled from "styled-components";
import ReactFlagsSelect from "react-flags-select";
import { generateTransactionID } from "../../../library/utilities";
import { UserProfileData, countryCodes } from "../../../library/constants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BsCheck, BsDot, BsPencil } from "react-icons/bs";
import * as BiIcons from "react-icons/bi";
import phoneList from "../../../files/phone.json";
import { SessionManagement } from "../../../library/SessionManagement";
import { valueSummation, weightSummation } from "../Utilities";
import EditPackageDimensionsDialog from "../../../modals/EditPackageDimensionsDialog";
import EditContentDialog from "../../../modals/EditContentDialog";
import ProhibitedItemsDialog from "../../../modals/ProhibitedItemsDialog";

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right: 13px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor: pointer;
  padding-left: 2px;

  svg {
    font-size: 18px;
  }
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const PackageTab = styled.div`
  background-color: #d3d3d3;
  font-size: 18px;
  padding: 4px 3rem;
  font-weight: 600;
  cursor: pointer;
`;

const PackageActiveTab = styled.div`
  background-color: #008561;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  padding: 4px 3rem;
  cursor: pointer;
`;

const CreateInterShipmentFormMulti = () => {
  const navigator = useNavigate();
  let generated_id = generateTransactionID(15);

  const form = useRef(null);

  const [contentFields, setContentFields] = useState(3);
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [editDimensions, setEditDimensions] = useState(false);
  const [editContents, setEditContents] = useState(null);
  const [transaction_id, setTransactionId] = useState("");

  const [packages, setPackages] = useState([
    {
      number_of_items: 0,
      package_value: 0,
      package_weight: 0,
      package_length: "",
      package_width: "",
      package_height: "",
      package_items: [
        {
          description: "",
          weight: "",
          quantity: "",
          value: "",
          hs_code: "",
          image_url: "",
        },
      ],
    },
  ]);

  const [showSenderSuggestions, setShowSenderSuggestions] = useState(false);
  const [senderSuggestions, setSenderSuggestions] = useState([]);

  const [senderCountryCode, setSelect] = useState("NG");
  const onSelect = (code) => {
    setSelect(code);

    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);
  };

  const [receiverCountryCode, setReceiverCountryCode] = useState("US");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");

  const onSelectReceiverCountry = (code) => {
    setReceiverCountryCode(code);

    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);

    const countryCode =
      isNaN(code.substring(0, 1)) === false
        ? phoneList[code]
        : "+" + phoneList[code];
    setReceiverPhoneNumber(countryCode);
  };

  const [startDate, setStartDate] = useState(new Date());

  const [isCalculatingShipment, setIsCalculatingShipment] = useState(false);
  const [isCreatingShipment, setIsCreatingShipment] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [pickupLocations, setPickupLocations] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);

  const [dhlPrice, setDHLPrice] = useState("0");
  const [operatorPrice, setOperatorPrice] = useState("0");

  const [isShipmentCalculated, setIsShipmentCalculated] = useState(false);
  const [showPhase2Shipment, setShowPhase2Shipment] = useState(false);

  const [senderName, setSenderName] = useState("");
  const [senderEmailAddress, setSenderEmailAddress] = useState("");
  const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
  const [showProhibited, setShowProhibited] = useState(false);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [requestedPickup, setRequestedPickup] = useState("No");

  const [carriers, setCarriers] = useState([]);

  const [selectedCarrierName, setSelectedCarrierName] = useState("");
  const [selectedCarrierCostPrice, setSelectedCarrierCostPrice] = useState("");
  const [selectedCarrierSellingPrice, setSelectedCarrierSellingPrice] =
    useState("");
  const [selectedCarrierInsurance, setSelectedCarrierInsurance] =
    useState("No");

  const selectSender = (customerName, customerNumber, emailAddress) => {
    setSenderName(customerName);
    setSenderEmailAddress(emailAddress);
    setSenderPhoneNumber(customerNumber);

    setShowSenderSuggestions(false);
  };

  const onChangeSenderDetails = (event, type) => {
    if (type === "senderEmailAddress") {
      setSenderEmailAddress(event.target.value);
    } else if (type === "senderPhoneNumber") {
      setSenderPhoneNumber(event.target.value);
    } else if (type === "senderName") {
      setSenderName(event.target.value);
      searchForCustomers(event);
    }

    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);
  };

  const onChangeReceiverDetails = (event, type) => {
    setIsShipmentCalculated(false);
    setShowPhase2Shipment(false);

    if (type === "ReceiverPhone") {
      setReceiverPhoneNumber(event.target.value);
    }
  };

  const fetchAllPackageItems = () => {
    let allPackageItems = [];

    // Outer loop to iterate through the varObj array
    for (let i = 0; i < packages.length; i++) {
      // Inner loop to iterate through the sports array for each object
      for (let j = 0; j < packages[i].package_items.length; j++) {
        // Push each sport object into the allSports array
        allPackageItems.push(packages[i].package_items[j]);
      }
    }

    return allPackageItems;
  };

  const fetchPackageTotalWeight = () => {
    let packageWeight = packages?.reduce((accumulator, currentObject) => {
      return accumulator + parseFloat(currentObject?.package_weight);
    }, 0);

    return packageWeight;
  };

  const fetchPackageTotalValue = () => {
    let packageValue = packages?.reduce((accumulator, currentObject) => {
      return accumulator + parseFloat(currentObject?.package_value);
    }, 0);

    return packageValue;
  };

  useEffect(() => {
    setTransactionId(generated_id);
    setShowProhibited(true);

    axios
      .post(getStationsURL, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const pickupLocations = response["data"]["data"]["data"];
          console.log(pickupLocations);

          setPickupLocations(pickupLocations);
        }
      })
      .catch(function (error) {
        return error;
      });
  }, []);

  const getAdditionalServices = async () => {
    await axios
      .post(getAdditionalServiceUrl, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const services = response["data"]["data"];

          setAdditionalServices(services);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const getPackaging = async () => {
    await axios
      .post(getPackagingURL, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const packaging = response["data"]["data"];

          setContainerTypes(packaging);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const getTotal = () => {
    let additional_fee = 0;

    selectedServices?.forEach((service_id) => {
      let service = additionalServices.find((serv) => serv?.id === service_id);
      additional_fee += service?.fee;
    });

    return requestedPickup === "Yes"
      ? selectedCarrierSellingPrice + pickupPrice + additional_fee
      : selectedCarrierSellingPrice + additional_fee;
  };

  useEffect(() => {
    getAdditionalServices();
    getPackaging();
  }, []);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isCreatingShipment) {
      return;
    }

    clearErrorMessage();

    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    let allPackageItems = fetchAllPackageItems();

    let packageWeight = fetchPackageTotalWeight();

    let packageValue = fetchPackageTotalValue();

    let additional_fee = 0;

    selectedServices?.forEach((service_id) => {
      let service = additionalServices.find((serv) => serv?.id === service_id);
      additional_fee += service?.fee;
    });

    formValues["transaction_id"] = transaction_id;
    formValues["sender_country_code"] = senderCountryCode;
    formValues["receiver_country_code"] = receiverCountryCode;
    formValues["requested_pickup"] = requestedPickup;
    formValues["cost_price"] = selectedCarrierCostPrice;

    formValues["amount"] =
      requestedPickup === "Yes"
        ? selectedCarrierSellingPrice + pickupPrice + additional_fee
        : selectedCarrierSellingPrice + additional_fee;
    formValues["pickup_fee"] = pickupPrice;
    formValues["insurance"] = selectedCarrierInsurance;
    formValues["carrier"] = selectedCarrierName;
    formValues["length"] = packages[0]?.package_length;
    formValues["breadth"] = packages[0]?.package_width;
    formValues["height"] = packages[0]?.package_height;
    formValues["weight"] = packageWeight;
    formValues["declared_value"] = packageValue;
    formValues["packages"] = packages;
    formValues["additional_services"] = selectedServices;

    const newData = { ...UserProfileData(), ...formValues };

    setIsCreatingShipment(true);

    axios
      .post(createInternationalShipmentNewURL, newData)
      .then(function (response) {
        console.log(response);
        setIsCreatingShipment(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          window.scrollTo(0, 0);

          const shipmentID = response["data"]["data"]["shipment_id"];

          setSuccessVisibility(true);
          setSuccessMessage(
            "Shipment has been successfully created. Redirecting..."
          );

          setTimeout(() => {
            navigator("/shipment/" + shipmentID);
          }, 3000);
        } else {
          setTransactionId(generated_id);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const [calculatingPickup, setCalculatingPickup] = useState(false);
  const [pickupPrice, setPickupPrice] = useState("0");

  const calculatePickupCost = () => {
    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    formValues["transaction_id"] = `${generateTransactionID(
      15
    )}+1+1+1.23.0+10-2-2022 0:13`;
    formValues["pickup_location"] = formValues["sender_address"];

    const newData = { ...UserProfileData(), ...formValues };
    setCalculatingPickup(true);

    axios
      .post(calculatePickupCostUrl, newData)
      .then(function (response) {
        setCalculatingPickup(false);
        if (response["data"]["success"] === true) {
          const pickupPrice = response["data"]["data"];

          setPickupPrice(pickupPrice);
        } else {
          setPickupPrice("0");
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const calculateDHLPrice = () => {
    const data = new FormData(form.current);

    const formValues = {};

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    let packageWeight = fetchPackageTotalWeight();

    formValues["transaction_id"] = generateTransactionID(15);
    formValues["sender_country_code"] = senderCountryCode;
    formValues["receiver_country_code"] = receiverCountryCode;
    formValues["weight"] = packageWeight;
    formValues["length"] = packages[0]?.package_length;
    formValues["breadth"] = packages[0]?.package_width;
    formValues["height"] = packages[0]?.package_height;

    const newData = { ...formValues, ...UserProfileData() };

    setIsCalculatingShipment(true);

    axios
      .post(calculateDHLURL, newData)
      .then(function (response) {
        console.log(response);
        setIsCalculatingShipment(false);
        if (response["data"]["success"] === true) {
          const dhlPrice = response["data"]["data"]["dhl_price"];
          const operatorPrice = response["data"]["data"]["operator_price"];

          setDHLPrice(dhlPrice);
          setOperatorPrice(operatorPrice);

          const carriers = response["data"]["data"]["carriers"];
          setShowPhase2Shipment(true);

          console.log(carriers);

          carriers.forEach((carrier) => {
            carrier["selected"] = false;
          });
          setCarriers(carriers);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);

          console.log("Not getting here");
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleContinueShipment = () => {
    setShowPhase2Shipment(true);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const ContentFields = () => {
    return (
      <section>
        <div className="d-flex w-100 gap-2 py-4">
          {Array(packages?.length)
            ?.fill(0)
            ?.map((_, id) =>
              selectedPackage === id ? (
                <PackageActiveTab onClick={() => setSelectedPackage(id)}>
                  {id + 1}
                </PackageActiveTab>
              ) : (
                <PackageTab onClick={() => setSelectedPackage(id)}>
                  {id + 1}
                </PackageTab>
              )
            )}
        </div>
        <Row className="form-row">
          <Row className="form-row mb-3">
            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Length">Length</label>
              <input
                // onChange={handleEditPackage}
                disabled
                value={packages[selectedPackage]?.package_length}
                name="package_length"
                className="form-control"
                type="text"
              />
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Breadth">Breadth</label>
              <input
                // onChange={handleEditPackage}
                disabled
                value={packages[selectedPackage]?.package_width}
                name="package_width"
                className="form-control"
                type="text"
              />
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Height">Height</label>
              <div className="d-flex gap-1">
                <input
                  // onChange={handleEditPackage}
                  disabled
                  value={packages[selectedPackage]?.package_height}
                  name="package_height"
                  className="form-control"
                  type="text"
                />
                <ContentFieldsActionButton
                  onClick={() => setEditDimensions(true)}
                >
                  <BsPencil />
                </ContentFieldsActionButton>
              </div>
            </Col>
          </Row>
          {packages[selectedPackage]?.package_items?.map((package_item, id) => (
            <Row className="form-row mb-3" key={id}>
              <Col className="form-group col-xl-5 col-md-6 col-12 mb-3 mb-xl-0">
                <label
                  htmlFor="Length"
                  className="d-flex gap-2 align-items-center"
                >
                  Description{" "}
                  {package_item?.image_url !== "" && (
                    <BsCheck style={{ color: "green" }} />
                  )}
                </label>
                <input
                  name="description"
                  className="form-control"
                  type="text"
                  value={package_item?.description}
                  disabled
                  // onChange={(e) => handleEditPackageItem(e, id)}
                  placeholder=""
                />
              </Col>

              <Col className="form-group col-xl-1 col-md-6 col-12 mb-3 mb-xl-0">
                <label htmlFor="Weight">Weight</label>
                <input
                  name="weight"
                  className="form-control"
                  type="text"
                  value={package_item?.weight}
                  disabled
                  // onChange={(e) => handleEditPackageItem(e, id)}
                  placeholder=""
                />
              </Col>

              <Col className="form-group col-xl-2 col-md-6 col-12 mb-3 mb-xl-0">
                <label htmlFor="Value">Value (USD)</label>
                <input
                  name="value"
                  className="form-control"
                  value={package_item?.value}
                  disabled
                  // onChange={(e) => handleEditPackageItem(e, id)}
                  type="number"
                />
              </Col>

              <Col className="form-group col-xl-1 col-md-2">
                <label htmlFor="Quantity">Quantity</label>
                <input
                  name="quantity"
                  className="form-control"
                  type="text"
                  value={package_item?.quantity}
                  disabled
                  // onChange={(e) => handleEditPackageItem(e, id)}
                  placeholder=""
                />
              </Col>

              <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                <label htmlFor="HsCode">HS Code</label>
                <div className="d-flex gap-1">
                  <input
                    name="hs_code"
                    className="form-control"
                    value={package_item?.hs_code}
                    disabled
                    type="tel"
                    placeholder=""
                  />
                  <ContentFieldsActionButton
                    onClick={() => setEditContents(id)}
                  >
                    <BsPencil />
                  </ContentFieldsActionButton>
                  <ContentFieldsActionButton
                    onClick={() => handleRemovePackageItem(id)}
                  >
                    -
                  </ContentFieldsActionButton>
                </div>
              </Col>
            </Row>
          ))}
        </Row>
        <div className="py-3 d-flex justify-content-between">
          <p>
            Total Number of Items:{" "}
            <span style={{ fontSize: "18px" }}>
              {fetchAllPackageItems()?.length}
            </span>
          </p>
          <p>
            Total Package(s) Weight:{" "}
            <span style={{ fontSize: "18px" }}>
              {isNaN(fetchPackageTotalWeight())
                ? "0"
                : fetchPackageTotalWeight()}{" "}
              KG
            </span>
          </p>
          <p>
            Total Package(s) Value:{" "}
            <span style={{ fontSize: "18px" }}>
              NGN{" "}
              {isNaN(fetchPackageTotalValue())
                ? "0"
                : fetchPackageTotalValue()?.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
            </span>
          </p>
        </div>
      </section>
    );
  };

  const searchForCustomers = (e) => {
    const query = e.target.value;

    if (query.toString().trim() === "") {
      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    axios.post(searchForCustomersURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setShowSenderSuggestions(true);
      setSenderSuggestions(customers);
    });
  };

  // Add New Package
  const handleAddPackage = () => {
    let packagesCopy = [...packages];
    packagesCopy.push({
      number_of_items: 0,
      package_value: 0,
      package_weight: 0,
      package_length: "1",
      package_width: "1",
      package_height: "1",
      description: "Package",
      package_items: [
        {
          description: "",
          weight: "",
          quantity: "",
          value: "",
          hs_code: "",
          image_url: "",
        },
      ],
    });

    setPackages(packagesCopy);
    setSelectedPackage(selectedPackage + 1);
  };

  // Remove a Package
  const handleRemovePackage = () => {
    if (packages?.length < 2) {
      return;
    }

    let packagesCopy = [...packages];
    packagesCopy.splice(selectedPackage, 1);

    setPackages(packagesCopy);
    setSelectedPackage(selectedPackage - 1);
  };

  // Edit package item
  const handleEditPackage = (packageObj) => {
    setPackages((prevPackages) => {
      // Create a deep copy of the selected package
      const updatedPackage = {
        ...prevPackages[selectedPackage],
        package_length: packageObj?.package_length,
        package_height: packageObj?.package_height,
        package_width: packageObj?.package_width,
        selected_package: packageObj?.selected_package,
        custom_dimension: packageObj?.custom_dimension,
      };

      // Create a new array with the updated package
      const updatedPackages = [...prevPackages];
      updatedPackages[selectedPackage] = updatedPackage;

      return updatedPackages;
    });
  };

  // Add new item to package
  const handleAddPackageItem = () => {
    let packagesCopy = [...packages];

    let packageItemCopy = { ...packagesCopy[selectedPackage] };

    packageItemCopy.package_items.push({
      description: "",
      weight: "",
      quantity: "",
      value: "",
      hs_code: "",
      image_url: "",
    });

    packagesCopy[selectedPackage] = packageItemCopy;

    setPackages(packagesCopy);
  };

  // Remove item from package
  const handleRemovePackageItem = (id) => {
    let packagesCopy = [...packages];

    let packageItemCopy = { ...packagesCopy[selectedPackage] };
    if (packageItemCopy?.package_items?.length < 2) {
      return;
    }
    let prevWeight = packageItemCopy?.package_items[id]?.weight ?? 0;
    let prevValue = packageItemCopy?.package_items[id]?.value ?? 0;

    packageItemCopy.number_of_items -= 1;
    packageItemCopy.package_weight -= parseFloat(prevWeight);
    packageItemCopy.package_value -= parseFloat(prevValue);

    packagesCopy[selectedPackage] = packageItemCopy;

    packagesCopy[selectedPackage].package_items.splice(id, 1);

    setPackages(packagesCopy);
  };

  // Edit package item
  const handleEditPackageItem = (packageObj) => {
    let packagesCopy = [...packages];
    console.log("ppf", packagesCopy);

    let packageItemCopy = { ...packagesCopy[selectedPackage] };

    packageItemCopy.package_items[editContents] = {
      ...packageItemCopy.package_items[editContents],
      weight: packageObj?.weight,
      description: packageObj?.description,
      value: packageObj?.value,
      quantity: packageObj?.quantity,
      hs_code: packageObj?.hs_code,
      image_url: packageObj?.image_url,
      name: packageObj?.description,
    };
    packageItemCopy.package_weight = weightSummation(
      packageItemCopy.package_items
    );
    packageItemCopy.package_value = valueSummation(
      packageItemCopy.package_items
    );

    packagesCopy[selectedPackage] = packageItemCopy;

    setPackages(packagesCopy);
  };

  const handleAdditionalServices = (id) => {
    let selectionCopy = [...selectedServices];
    if (selectedServices?.includes(id)) {
      setSelectedServices(selectionCopy.filter((serv) => serv !== id));
    } else {
      selectionCopy.push(id);
      setSelectedServices(selectionCopy);
    }
  };

  return (
    <div className="">
      {isError === false ? null : (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      )}

      {isSuccess === false ? null : (
        <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
      )}

      <EditPackageDimensionsDialog
        isModalVisible={editDimensions}
        handleCloseDialog={() => setEditDimensions(false)}
        packageObj={packages[selectedPackage]}
        handleSubmit={handleEditPackage}
      />

      <EditContentDialog
        isModalVisible={editContents !== null}
        handleCloseDialog={() => setEditContents(null)}
        packageObj={packages[selectedPackage]?.package_items[editContents]}
        handleSubmit={handleEditPackageItem}
        shipmentType={"International"}
        insurance={selectedCarrierInsurance}
      />

      <ProhibitedItemsDialog
        isModalVisible={showProhibited}
        handleCloseDialog={() => setShowProhibited(false)}
      />

      <form ref={form} onSubmit={handleSubmit}>
        <section className="mt-5">
          <section className="mb-2 d-flex justify-content-end">
            <SubmitButton
              onClick={() => {
                setShowProhibited(true);
              }}
              type="button"
              className="confirm-button"
            >
              <span className="button-text">SHOW PROHIBITED ITEMS</span>
            </SubmitButton>
          </section>
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>1</SteppedNumber>
            <SteppedTitle className="mx-2">Sender Details</SteppedTitle>
          </div>
          <Row className="form-row">
            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Full Name">Full Name</label>
              <input
                onChange={(e) => onChangeSenderDetails(e, "senderName")}
                name="sender_name"
                value={senderName}
                className="form-control"
                type="text"
              />
              <div
                style={{ width: "31%" }}
                className={`suggestions-field ${
                  showSenderSuggestions ? "suggestions-field-visible" : null
                }`}
              >
                {senderSuggestions.map((suggestions) => {
                  const fullName = suggestions.fullname;
                  const phoneNumber = suggestions.phone;
                  const emailAddress = suggestions.email;
                  return (
                    <div
                      onClick={(e) =>
                        selectSender(fullName, phoneNumber, emailAddress)
                      }
                      key={suggestions.id}
                      className="single-suggestion"
                    >
                      <span>{fullName}</span>
                      <div className="d-flex flex-row align-items-center">
                        <span style={{ fontSize: 13, marginTop: -5 }}>
                          {phoneNumber}
                        </span>
                        <BsDot />
                        <span style={{ fontSize: 13, marginTop: -5 }}>
                          {emailAddress}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Email">Email</label>
              <input
                onChange={(e) => onChangeSenderDetails(e, "senderEmailAddress")}
                value={senderEmailAddress}
                name="sender_email"
                className="form-control"
                type="text"
                placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Phone Number">Phone Number</label>
              <input
                onChange={(e) => onChangeSenderDetails(e, "senderPhoneNumber")}
                value={senderPhoneNumber}
                name="sender_phone"
                className="form-control"
                type="text"
                placeholder=""
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Address">Address</label>
              <input
                name="sender_address"
                className="form-control"
                type="text"
                maxLength={45}
              />
            </Col>

            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Address">Address 2 (Optional)</label>
              <input
                name="sender_address_2"
                className="form-control"
                type="text"
                placeholder=""
                maxLength={45}
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Sender Country">Country</label>
              <ReactFlagsSelect
                className="countries-text-field"
                selected={senderCountryCode}
                onSelect={onSelect}
                // countries={["fi", "NG"]}
                countries={countryCodes}
                searchable={true}
              />
            </Col>

            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Sender City">City</label>
              <input name="sender_city" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Sender State">State</label>
              <input name="sender_state" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Sender Postal">Postal Code</label>
              <input
                name="sender_postal_code"
                className="form-control"
                type="text"
              />
            </Col>
          </Row>
        </section>

        <section className="mt-5">
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>2</SteppedNumber>
            <SteppedTitle className="mx-2">Receiver Details</SteppedTitle>
          </div>
          <Row className="form-row">
            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Sender Country">Country</label>
              <ReactFlagsSelect
                className="countries-text-field"
                selected={receiverCountryCode}
                onSelect={onSelectReceiverCountry}
                searchable={true}
                countries={countryCodes}
              />
            </Col>

            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Receiver City">City</label>
              <input
                name="receiver_city"
                className="form-control"
                type="text"
              />
            </Col>

            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Receiver State">State</label>
              <input
                name="receiver_county"
                className="form-control"
                type="text"
              />
            </Col>

            <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Receiver Postal">Postal Code</label>
              <input
                name="receiver_postal_code"
                className="form-control"
                type="text"
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Address">Address</label>
              <input
                name="receiver_address"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
                maxLength={45}
              />
            </Col>

            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Address">Address 2 (Optional)</label>
              <input
                name="receiver_address_2"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
                placeholder=""
                maxLength={45}
              />
            </Col>
          </Row>

          <Row className="form-row mt-3">
            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Full Name">Full Name</label>
              <input
                name="receiver_name"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
              />
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Email">Email</label>
              <input
                name="receiver_email"
                onChange={(e) => onChangeReceiverDetails(e, "")}
                className="form-control"
                type="text"
                placeholder=""
              />
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Phone Number">Phone Number</label>
              <input
                name="receiver_phone"
                onChange={(e) => onChangeReceiverDetails(e, "ReceiverPhone")}
                value={receiverPhoneNumber}
                className="form-control"
                type="text"
                placeholder=""
              />
            </Col>
          </Row>
        </section>

        <section className="mt-5">
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>3</SteppedNumber>
            <SteppedTitle className="mx-2">Extra Details</SteppedTitle>
          </div>
          <Row className="form-row mt-3">
            {/* <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Weight">Weight</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="weight" className="form-control"  type="text" />
            </Col> */}

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Value">Parcel Type</label>
              <select name="parcel_type" className="form-select">
                <option value="Parcel">Parcel</option>
                <option value="Document">Document</option>
              </select>
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Value">Insurance</label>
              <select
                name="insurance"
                value={selectedCarrierInsurance}
                onChange={(e) => setSelectedCarrierInsurance(e.target.value)}
                className="form-select"
              >
                <option value="No">No</option>
                <option value="Capped">Capped Insurance</option>
                <option value="Yes">Yes</option>
              </select>
            </Col>
          </Row>
          <Row className="form-row mt-3">
            <Col className="form-group col-xl-12 col-md-12 col-12 mb-3 mb-xl-0">
              <label htmlFor="Value">Additional Services</label>
              <Row className="form-row">
                {additionalServices?.map((service) => (
                  <Col
                    key={service?.id}
                    className="form-group mt-3 col-xl-4 justify-content-between col-md-6 col-12 mb-3 d-flex gap-2 mb-xl-0"
                  >
                    <label htmlFor="Value" style={{ fontSize: 16 }}>
                      {service?.service_name} - (N {service?.fee})
                    </label>
                    <input
                      name={"services"}
                      value={selectedServices?.includes(service?.id)}
                      onClick={() => handleAdditionalServices(service?.id)}
                      type="checkbox"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          {/* <Row className="form-row mt-3">
            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Value">Item Value</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="declared_value" className="form-control"  type="text" />
            </Col>

            <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Value">Value Currency</label>
              <select name="declared_value_currency" className="form-select">
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </select>
            </Col>
          </Row> */}

          {/* <Row className="form-row mt-3">
            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Length">Length</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="length" className="form-control" type="text" />
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Breadth">Breadth</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="breadth" className="form-control"  type="text" />
            </Col>

            <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
              <label htmlFor="Height">Height</label>
              <input onChange={(e) => onChangeSenderDetails(e, "")} name="height" className="form-control"  type="text" />
            </Col>
          </Row> */}
        </section>

        <section className="mt-5">
          <div className="d-flex align-items-center mb-2">
            <SteppedNumber>4</SteppedNumber>
            <SteppedTitle className="mx-2">Package Details</SteppedTitle>
            <div className=" ps-4">
              <ContentFieldsActionButton onClick={handleAddPackage}>
                +
              </ContentFieldsActionButton>
            </div>
            <div className=" ps-4">
              <ContentFieldsActionButton onClick={handleRemovePackage}>
                -
              </ContentFieldsActionButton>
            </div>
          </div>
          {/* { createContentFields() } */}
          <ContentFields />
          <div className="d-flex justify-content-center align-items-center gap-2">
            Add Package Item
            <ContentFieldsActionButton onClick={handleAddPackageItem}>
              +
            </ContentFieldsActionButton>
          </div>

          {isShipmentCalculated === false && isCalculatingShipment === false ? (
            <div className="d-flex justify-content-end mt-5">
              <SubmitButton
                onClick={() => {
                  calculateDHLPrice();
                  calculatePickupCost();
                }}
                type="button"
                className="confirm-button"
              >
                <span className="button-text">CALCULATE COST</span>
              </SubmitButton>
            </div>
          ) : isShipmentCalculated === true ? null : (
            <div className="d-flex justify-content-end mt-5">
              <SubmitButton
                onClick={() => {
                  calculateDHLPrice();
                  calculatePickupCost();
                }}
                type="button"
                className="confirm-button"
              >
                <span className="spinner-border spinner-grow-sm" />
              </SubmitButton>
            </div>
          )}

          {isShipmentCalculated === true && showPhase2Shipment === false ? (
            <div className="d-flex justify-content-end mt-5">
              <SubmitButton type="button" className="confirm-button">
                <span onClick={handleContinueShipment} className="button-text">
                  CONTINUE
                </span>
              </SubmitButton>
            </div>
          ) : null}
        </section>

        {showPhase2Shipment === false ? null : (
          <div>
            <section className="mt-5">
              <div className="d-flex align-items-center mb-2">
                <SteppedNumber>5</SteppedNumber>
                <SteppedTitle className="mx-2">
                  Carriers{" "}
                  <span style={{ fontSize: 15, fontStyle: "italic" }}>
                    (Select a carrier)
                  </span>
                </SteppedTitle>
              </div>
              <table style={{ width: "100%" }}>
                <tbody style={{ width: "100%" }}>
                  {carriers.map((carrier) => {
                    const selectCarrier = (selectedCarrier) => {
                      let newCarriers = [...carriers];
                      newCarriers.forEach((transporter) => {
                        transporter["selected"] = false;
                      });

                      const carrierIndex = carriers.findIndex(
                        (carrier) =>
                          carrier.carrier_id === selectedCarrier.carrier_id
                      );

                      newCarriers[carrierIndex] = {
                        ...newCarriers[carrierIndex],
                        selected: !newCarriers[carrierIndex].selected,
                      };

                      setCarriers(newCarriers);

                      setSelectedCarrierCostPrice(carrier.cost_price);
                      setSelectedCarrierSellingPrice(carrier.selling_price);
                      setSelectedCarrierName(selectedCarrier.carrier_name);

                      if (selectedCarrier.carrier_name === "DHL + Insurance") {
                        setSelectedCarrierInsurance("Yes");
                        console.log("Yes");
                      } else {
                        setSelectedCarrierInsurance("No");
                        console.log("No");
                      }
                    };

                    return (
                      <tr
                        onClick={() => selectCarrier(carrier)}
                        style={{ cursor: "pointer" }}
                        key={carrier.carrier_id}
                      >
                        <td className="description" style={{ width: 100 }}>
                          <div className="d-flex align-items-center">
                            <img
                              className="icon"
                              src={carrier.carrier_logo}
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="amount">
                          <div className="d-flex flex-column">
                            <span style={{ fontSize: 18 }}>
                              {carrier.carrier_name}
                            </span>
                            <span>{carrier.delivery_time}</span>
                          </div>
                        </td>
                        {SessionManagement.getCompanyID() !== "44" ? (
                          <>
                            <td className="amount">
                              <div className="d-flex flex-column">
                                <span style={{ fontSize: 18 }}>
                                  ₦{Math.ceil(carrier.cost_price)}
                                </span>
                                <span>Cost Price</span>
                              </div>
                            </td>
                          </>
                        ) : null}

                        <td className="amount">
                          <div className="d-flex flex-column">
                            <span style={{ fontSize: 18 }}>
                              ₦{Math.ceil(carrier.selling_price)}
                            </span>
                            <span>Selling Price</span>
                          </div>
                        </td>
                        <td className="status">
                          {carrier.selected === false ? null : (
                            <div
                              style={{
                                borderRadius: "50%",
                                float: "right",
                                height: 40,
                                width: 40,
                                backgroundColor: "#008561",
                                textAlign: "center",
                              }}
                            >
                              <BiIcons.BiCheck
                                style={{
                                  fontSize: 25,
                                  color: "white",
                                  marginTop: 7,
                                }}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {pickupPrice !== "0" && (
                <div className="amount my-3 px-3">
                  <div className="d-flex flex-column">
                    <span>Pickup Price:</span>
                    <span style={{ fontSize: 18 }}>
                      ₦{Math.ceil(pickupPrice)}
                    </span>
                  </div>
                </div>
              )}
            </section>

            <section className="mt-5">
              <div className="d-flex align-items-center mb-2">
                <SteppedNumber>6</SteppedNumber>
                <SteppedTitle className="mx-2">
                  Entire Package Details
                </SteppedTitle>
              </div>
              <Row className="form-row mt-3">
                <Col className="form-group col-xl-8 col-md-6 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Description">Description</label>
                  <input
                    name="description"
                    className="form-control"
                    type="text"
                    maxLength={80}
                  />
                </Col>

                <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Sender Postal">Payment Method</label>
                  <select name="payment_method" className="form-select">
                    <option value="">Select Payment Method</option>
                    <option value="Cash">Cash</option>
                    <option value="Card Payment">Card Payment</option>
                    <option value="Direct Transfer">Direct Transfer</option>
                    <option value="Wallet">Wallet</option>
                  </select>
                </Col>
              </Row>

              <Row className="form-row mt-3">
                {/* <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                <label htmlFor="Weight">Choose Container Type</label>
                <select name="container_type_id" className="form-select" >
                  <option value="">Choose container type</option>
                  {containerTypes.map((container) => {
                    return(<option key={container.id} value={container.id}>{container.name}</option>);
                  })}
                </select>
              </Col> */}

                <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Sender Country">Pickup Station</label>
                  <select name="pickup_detail_id" className="form-select">
                    <option value="">Choose pickup station</option>
                    {pickupLocations.map((locations) => {
                      return (
                        <option key={locations.id} value={locations.id}>
                          {locations.address}
                        </option>
                      );
                    })}
                  </select>
                </Col>

                {pickupPrice !== "0" && (
                  <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                    <label htmlFor="Value">Pickup Option</label>
                    <select
                      name="requested_pickup"
                      onChange={(e) => setRequestedPickup(e.target.value)}
                      className="form-select"
                    >
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </Col>
                )}

                <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Sender City">Pickup Date</label>
                  <ReactDatePicker
                    name="shipping_date"
                    className="form-control"
                    selected={startDate}
                    dateFormat="P"
                    onChange={(date) => setStartDate(date)}
                  />
                  {/* <input className="form-control" type="text" /> */}
                </Col>
              </Row>
              <Row className="form-row mt-3">
                <h6 className=" text-end" style={{ fontSize: 20 }}>
                  Total: ₦ {parseFloat(getTotal())?.toLocaleString("en-US")}
                </h6>
              </Row>
            </section>

            <div className="d-flex flex-row my-5 justify-content-end">
              <SubmitButton type="submit" className="confirm-button">
                {isCreatingShipment === false ? (
                  <span className="button-text">PROCESS SHIPMENT</span>
                ) : (
                  <span className="spinner-border spinner-grow-sm" />
                )}
              </SubmitButton>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateInterShipmentFormMulti;
