import * as BoxIcons from "react-icons/bi";
import * as React from "react";
import FundWalletDialog from "../../../modals/FundWalletDialog";
import { useEffect, useRef, useState } from "react";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import axios from "axios";
import {
    createInternationalShipmentURL,
    getAccountSettingsURL,
    getCompanySettings1URL,
    getDHLPickupLocationsURL,
    getShipmentPricingURL,
    updateAccountSettingsURL,
    getShipmentSettingsUrl,
    updateShipmentSettingsUrl,
} from "../../../library/URLs";
import { UserProfileData } from "../../../library/constants";
import styled from "styled-components";
import { generateTransactionID } from "../../../library/utilities";
import { SessionManagement } from "../../../library/SessionManagement";
import CancelShipmentDialog from "../../../modals/CancelShipmentDialog";
import AramexAccountDialog from "../../../modals/AramexAccountDialog";
import DHLAccountDialog from "../../../modals/DHLAccountDialog";

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const AccountSettings = () => {
    const form = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [intracityShipment, setIntracityShipment] = useState(true);
    const [interstateShipment, setInterstateShipment] = useState(true);
    const [internationalShipment, setInternationalShipment] = useState(true);

    //
    const [intracitySelfProcessor, setIntracitySelfProcessor] = useState(false);
    const [intracityDHLProcessor, setIntracityDHLProcessor] = useState(false);
    const [intracityTerminalProcessor, setIntracityTerminalProcessor] =
        useState(false);

    const [interstateSelfProcessor, setInterstateSelfProcessor] = useState(false);
    const [interstateDHLProcessor, setInterstateDHLProcessor] = useState(false);
    const [interstateTerminalProcessor, setInterstateTerminalProcessor] =
        useState(false);

    const [internationalSelfProcessor, setInternationalSelfProcessor] =
        useState(false);
    const [internationalDHLProcessor, setInternationalDHLProcessor] =
        useState(false);
    const [internationalTerminalProcessor, setInternationalTerminalProcessor] =
        useState(false); //

    const [availableCarriers, setAvailableCarriers] = useState({
        local: {
            self: false,
            topship: false,
            dhl: false,
        },
        interstate: {
            dhl: false,
            redstar: false,
            topship: false,
        },
        international: {
            dhl: false,
            fedex_priority: false,
            fedex_economy: false,
            ups: false,
            aaj: false,
            aramex: false,
            topship: false,
        },
        import: {
            dhl: false,
            topship: false,
            fedex_economy: false,
            fedex_priority: false,
            ups: false,
            aramex: false,
        },
    });

    const [intracityBikeVehicle, setIntracityBikeVehicle] = useState(true);
    const [intracityCarVehicle, setIntracityCarVehicle] = useState(true);
    const [intracityVanVehicle, setIntracityVanVehicle] = useState(true);
    const [intracityTruckVehicle, setIntracityTruckVehicle] = useState(true);
    const [intracityTopship, setIntracityTopship] = useState(false);

    const [interstateDHL, setInterstateDHL] = useState(false);
    const [interstateRedstar, setInterstateRedstar] = useState(false);
    const [interstateTopship, setInterstateTopship] = useState(false);

    const [internationalAramex, setInternationalAramex] = useState(false);
    const [internationalDHL, setInternationalDHL] = useState(false);
    const [internationalFedEx, setInternationalFedEx] = useState(false);
    const [internationalUPS, setInternationalUPS] = useState(false);
    const [internationalFedExPriority, setInternationalFedExPriority] =
        useState(false);
    const [internationalFedExEconomy, setInternationalFedExEconomy] =
        useState(false);
    const [internationalAAJ, setInternationalAAJ] = useState(false);
    const [internationalTopship, setInternationalTopship] = useState(false);

    const [importDHLProcessor, setImportDHLProcessor] = useState(false);
    const [importTopship, setImportTopship] = useState(false);
    const [importFedExPriority, setImportFedExPriority] = useState(false);
    const [importFedExEconomy, setImportFedExEconomy] = useState(false);
    const [importUPS, setImportUPS] = useState(false);
    const [importAramex, setImportAramex] = useState(false);

    const [cashPaymentMethod, setCashPaymentMethod] = useState(true);
    const [directTransferPaymentMethod, setDirectTransferPaymentMethod] =
        useState(true);
    const [cardPaymentMethod, setCardPaymentMethod] = useState(true);
    const [walletPaymentMethod, setWalletPaymentMethod] = useState(true);

    const [priceSettingsDialogVisibility, setPriceSettingsDialogVisibility] =
        useState(false);
    const [shipmentPricing, setShipmentPricing] = useState([]);

    const [showAramexAccountVisibility, setShowAramexAccountDialogVisibility] =
        useState(false);
    const [showDHLAccountVisibility, setShowDHLAccountDialogVisibility] =
        useState(false);

    useEffect(() => {
        const intracityProcessor =
            SessionManagement.getIntracityShipmentProcessor();
        const interstateProcessor =
            SessionManagement.getInterstateShipmentProcessor();
        const internationalProcessor =
            SessionManagement.getInternationalShipmentProcessor();

        if (intracityProcessor === "Self") {
            setIntracitySelfProcessor(true);
        } else if (intracityProcessor === "DHL") {
            setIntracityDHLProcessor(true);
        } else if (intracityProcessor === "Terminal") {
            setIntracityTerminalProcessor(true);
        }

        if (interstateProcessor === "Self") {
            setInterstateSelfProcessor(true);
        } else if (interstateProcessor === "DHL") {
            setInterstateDHLProcessor(true);
        } else if (interstateProcessor === "Terminal") {
            setInterstateTerminalProcessor(true);
        }

        if (internationalProcessor === "Self") {
            setInternationalSelfProcessor(true);
        } else if (internationalProcessor === "DHL") {
            setInternationalDHLProcessor(true);
        } else if (internationalProcessor === "Terminal") {
            setInternationalTerminalProcessor(true);
        }

        axios
            .post(getShipmentSettingsUrl, UserProfileData())
            .then(function (response) {
                console.log(response["data"]);
                if (response["data"]["success"] === true) {
                    let processors = response["data"]["data"];

                    const carriersCopy = { ...availableCarriers };

                    processors?.forEach((carrier_processor) => {
                        if (carrier_processor?.shipment_type === "International") {
                            if (carrier_processor?.carrier === "DHL") {
                                carriersCopy["international"]["dhl"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInternationalDHLProcessor(true);
                                } else {
                                    setInternationalDHLProcessor(false);
                                }
                            } else if (carrier_processor?.carrier === "Aramex") {
                                carriersCopy["international"]["aramex"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInternationalAramex(true);
                                } else {
                                    setInternationalAramex(false);
                                }
                            } else if (
                                // carrier_processor?.carrier ===
                                // "FedEx International Priority\u00ae"
                                carrier_processor?.carrier?.includes("Priority")
                            ) {
                                carriersCopy["international"]["fedex_priority"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInternationalFedExPriority(true);
                                } else {
                                    setInternationalFedExPriority(false);
                                }
                            } else if (
                                // carrier_processor?.carrier ===
                                // "FedEx International Economy\u00ae"
                                carrier_processor?.carrier?.includes("Economy")
                            ) {
                                carriersCopy["international"]["fedex_economy"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInternationalFedExEconomy(true);
                                } else {
                                    setInternationalFedExEconomy(false);
                                }
                            } else if (carrier_processor?.carrier === "AAJ") {
                                carriersCopy["international"]["aaj"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInternationalAAJ(true);
                                } else {
                                    setInternationalAAJ(false);
                                }
                            } else if (carrier_processor?.carrier === "Topship") {
                                carriersCopy["international"]["topship"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInternationalTopship(true);
                                } else {
                                    setInternationalTopship(false);
                                }
                            }
                        } else if (carrier_processor?.shipment_type === "Interstate") {
                            if (carrier_processor?.carrier === "DHL") {
                                carriersCopy["interstate"]["dhl"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInterstateDHLProcessor(true);
                                } else {
                                    setInterstateDHLProcessor(false);
                                }
                            } else if (carrier_processor?.carrier === "Redstar") {
                                carriersCopy["interstate"]["redstar"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInterstateRedstar(true);
                                } else {
                                    setInterstateRedstar(false);
                                }
                            } else if (carrier_processor?.carrier === "Topship") {
                                carriersCopy["interstate"]["topship"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setInterstateTopship(true);
                                } else {
                                    setInterstateTopship(false);
                                }
                            }
                        } else if (carrier_processor?.shipment_type === "Local") {
                            if (carrier_processor?.carrier === "Self") {
                                carriersCopy["local"]["self"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setIntracitySelfProcessor(true);
                                } else {
                                    setIntracitySelfProcessor(false);
                                }
                            } else if (carrier_processor?.carrier === "DHL") {
                                carriersCopy["local"]["dhl"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setIntracityDHLProcessor(true);
                                } else {
                                    setIntracityDHLProcessor(false);
                                }
                            } else if (carrier_processor?.carrier === "Topship") {
                                carriersCopy["local"]["topship"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setIntracityTopship(true);
                                } else {
                                    setIntracityTopship(false);
                                }
                            }
                        } else if (carrier_processor?.shipment_type === "Import") {
                            if (carrier_processor?.carrier === "Topship") {
                                carriersCopy["import"]["topship"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setImportTopship(true);
                                } else {
                                    setImportTopship(false);
                                }
                            } else if (
                                carrier_processor?.carrier ===
                                "FedEx International Priority\u00ae"
                            ) {
                                carriersCopy["import"]["fedex_priority"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setImportFedExPriority(true);
                                } else {
                                    setImportFedExPriority(false);
                                }
                            } else if (
                                carrier_processor?.carrier ===
                                "FedEx International Economy\u00ae"
                            ) {
                                carriersCopy["import"]["fedex_economy"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setImportFedExEconomy(true);
                                } else {
                                    setImportFedExEconomy(false);
                                }
                            } else if (carrier_processor?.carrier === "UPS") {
                                carriersCopy["import"]["ups"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setImportUPS(true);
                                } else {
                                    setImportUPS(false);
                                }
                            } else if (carrier_processor?.carrier === "Aramex") {
                                carriersCopy["import"]["aramex"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setImportAramex(true);
                                } else {
                                    setImportAramex(false);
                                }
                            } else if (carrier_processor?.carrier === "DHL") {
                                carriersCopy["import"]["DHL"] = true;
                                if (carrier_processor?.status === "Active") {
                                    setImportDHLProcessor(true);
                                } else {
                                    setImportDHLProcessor(false);
                                }
                            }
                        }
                    });

                    setAvailableCarriers(carriersCopy);
                    // const intracityShipment = response["data"]["data"]["local_shipment"];
                    // const interstateShipment = response["data"]["data"]["interstate_shipment"];
                    // const internationalShipment = response["data"]["data"]["international_shipment"];

                    // const directTransfer = response["data"]["data"]["bank_transfer"];
                    // const cash = response["data"]["data"]["cash"];
                    // const cardPayment = response["data"]["data"]["card_payment"];
                    // const wallet = response["data"]["data"]["wallet"];

                    // const motorbike = response["data"]["data"]["motorbike"];
                    // const car = response["data"]["data"]["car"];
                    // const minivan = response["data"]["data"]["minivan"];
                    // const truck = response["data"]["data"]["truck"];
                }
            })
            .catch(function (error) {
                return error;
            });
    }, []);

    const toggleIntracityProcessor = (intracityProcessor) => {
        if (intracityProcessor === "intracitySelfProcessor") {
            setIntracitySelfProcessor(true);
            setIntracityDHLProcessor(false);
            setIntracityTerminalProcessor(false);
        } else if (intracityProcessor === "intracityDHLProcessor") {
            setIntracitySelfProcessor(false);
            setIntracityDHLProcessor(true);
            setIntracityTerminalProcessor(false);
        } else if (intracityProcessor === "intracityTerminalProcessor") {
            setIntracitySelfProcessor(false);
            setIntracityDHLProcessor(false);
            setIntracityTerminalProcessor(true);
        }
    };

    const toggleInterstateProcessor = (interstateProcessor) => {
        if (interstateProcessor === "interstateSelfProcessor") {
            setInterstateSelfProcessor(true);
            setInterstateDHLProcessor(false);
            setInterstateTerminalProcessor(false);
        } else if (interstateProcessor === "interstateDHLProcessor") {
            setInterstateSelfProcessor(false);
            setInterstateDHLProcessor(true);
            setInterstateTerminalProcessor(false);
        } else if (interstateProcessor === "interstateTerminalProcessor") {
            setInterstateSelfProcessor(false);
            setInterstateDHLProcessor(false);
            setInterstateTerminalProcessor(true);
        }
    };

    const toggleInternationalProcessor = (internationalProcessor) => {
        if (internationalProcessor === "internationalSelfProcessor") {
            setInternationalSelfProcessor(true);
            setInternationalDHLProcessor(false);
            setInternationalTerminalProcessor(false);
        } else if (internationalProcessor === "internationalDHLProcessor") {
            setInternationalSelfProcessor(false);
            setInternationalDHLProcessor(true);
            setInternationalTerminalProcessor(false);
        } else if (internationalProcessor === "internationalTerminalProcessor") {
            setInternationalSelfProcessor(false);
            setInternationalDHLProcessor(false);
            setInternationalTerminalProcessor(true);
        }
    };

    const toggleShipmentTypeCheckBox = (shipmentType) => {
        if (shipmentType === "intracityShipment") {
            setIntracityShipment(!intracityShipment);
        } else if (shipmentType === "interstateShipment") {
            setInterstateShipment(!interstateShipment);
        } else if (shipmentType === "internationalShipment") {
            setInternationalShipment(!internationalShipment);
        }
    };

    const toggleShipmentVehicleCheckBox = (vehicleType) => {
        if (vehicleType === "intracityBikeVehicle") {
            setIntracityBikeVehicle(!intracityBikeVehicle);
        } else if (vehicleType === "intracityCarVehicle") {
            setIntracityCarVehicle(!intracityCarVehicle);
        } else if (vehicleType === "intracityVanVehicle") {
            setIntracityVanVehicle(!intracityVanVehicle);
        } else if (vehicleType === "intracityTruckVehicle") {
            setIntracityTruckVehicle(!intracityTruckVehicle);
        }
    };

    const toggleInternationalCarrier = (carrier) => {
        if (carrier === "Aramex") {
            setInternationalAramex(!internationalAramex);
        } else if (carrier === "DHL") {
            setInternationalDHL(!internationalDHL);
        } else if (carrier === "FedEx") {
            setInternationalFedEx(!internationalFedEx);
        } else if (carrier === "UPS") {
            setInternationalUPS(!internationalUPS);
        }
    };

    const toggleInterstateCarrier = (carrier) => {
        if (carrier === "DHL") {
            setInterstateDHL(!interstateDHL);
        } else if (carrier === "Redstar") {
            setInterstateRedstar(!interstateRedstar);
        }
    };

    const togglePaymentMethod = (paymentMethod) => {
        if (paymentMethod === "cash") {
            setCashPaymentMethod(!cashPaymentMethod);
        } else if (paymentMethod === "directTransfer") {
            setDirectTransferPaymentMethod(!directTransferPaymentMethod);
        } else if (paymentMethod === "cardPayment") {
            setCardPaymentMethod(!cardPaymentMethod);
        } else if (paymentMethod === "wallet") {
            setWalletPaymentMethod(!walletPaymentMethod);
        }
    };

    const toggleLocalShipmentProcessor = (processor) => {
        if (processor === "Self") {
            setIntracitySelfProcessor(!intracitySelfProcessor);
        } else if (processor === "DHL") {
            setIntracityDHLProcessor(!intracityDHLProcessor);
        } else if (processor === "Topship") {
            setIntracityTopship(!intracityTopship);
        }
    };

    const toggleInterStateShipmentProcessor = (processor) => {
        if (processor === "DHL") {
            setInterstateDHLProcessor(!interstateDHLProcessor);
        } else if (processor === "Redstar") {
            setInterstateRedstar(!interstateRedstar);
        } else if (processor === "Topship") {
            setInterstateTopship(!interstateTopship);
        }
    };

    const toggleInternationalShipmentProcessor = (processor) => {
        if (processor === "DHL") {
            setInternationalDHLProcessor(!internationalDHLProcessor);
        } else if (processor === "FedEx Priority") {
            setInternationalFedExPriority(!internationalFedExPriority);
        } else if (processor === "FedEx Economy") {
            setInternationalFedExEconomy(!internationalFedExEconomy);
        } else if (processor === "Aramex") {
            setInternationalAramex(!internationalAramex);
        } else if (processor === "UPS") {
            setInternationalUPS(!internationalUPS);
        } else if (processor === "AAJ") {
            setInternationalAAJ(!internationalAAJ);
        } else if (processor === "Topship") {
            setInternationalTopship(!internationalTopship);
        }
    };

    const toggleImportShipmentProcessor = (processor) => {
        if (processor === "DHL") {
            setImportDHLProcessor(!importDHLProcessor);
        } else if (processor === "FedEx Priority") {
            setImportFedExPriority(!importFedExPriority);
        } else if (processor === "FedEx Economy") {
            setImportFedExEconomy(!importFedExEconomy);
        } else if (processor === "Topship") {
            setImportTopship(!importTopship);
        } else if (processor === "UPS") {
            setImportUPS(!importUPS);
        } else if (processor === "Aramex") {
            setImportAramex(!importAramex);
        }
    };

    const updateAccountSettings = (e) => {
        e.preventDefault();

        setErrorVisibility(false);

        const formValues = {
            // "transaction_id": "37776874107070088853",
            intracity_processor: [
                {
                    name: "DHL",
                    status: intracityDHLProcessor ? "Active" : "Inactive",
                },
                {
                    name: "Self",
                    status: intracitySelfProcessor ? "Active" : "Inactive",
                },
                {
                    name: "Topship",
                    status: intracityTopship ? "Active" : "Inactive",
                },
            ],
            interstate_processor: [
                {
                    name: "DHL",
                    status: interstateDHLProcessor ? "Active" : "Inactive",
                },
                {
                    name: "Redstar",
                    status: interstateRedstar ? "Active" : "Inactive",
                },
                {
                    name: "Topship",
                    status: interstateTopship ? "Active" : "Inactive",
                },
            ],
            international_processor: [
                {
                    name: "DHL",
                    status: internationalDHLProcessor ? "Active" : "Inactive",
                },
                {
                    name: "FedEx International Priority®",
                    status: internationalFedExPriority ? "Active" : "Inactive",
                },
                {
                    name: "FedEx International Economy®",
                    status: internationalFedExEconomy ? "Active" : "Inactive",
                },
                {
                    name: "Aramex",
                    status: internationalAramex ? "Active" : "Inactive",
                },
                {
                    name: "UPS",
                    status: internationalUPS ? "Active" : "Inactive",
                },
                {
                    name: "AAJ",
                    status: internationalAAJ ? "Active" : "Inactive",
                },
                {
                    name: "Topship",
                    status: internationalTopship ? "Active" : "Inactive",
                },
            ],
            import_processor: [
                {
                    name: "DHL",
                    status: importDHLProcessor ? "Active" : "Inactive",
                },
                {
                    name: "FedEx International Priority®",
                    status: importFedExPriority ? "Active" : "Inactive",
                },
                {
                    name: "FedEx International Economy®",
                    status: importFedExEconomy ? "Active" : "Inactive",
                },
                {
                    name: "Aramex",
                    status: importAramex ? "Active" : "Inactive",
                },
                {
                    name: "UPS",
                    status: importUPS ? "Active" : "Inactive",
                },
                {
                    name: "Topship",
                    status: importTopship ? "Active" : "Inactive",
                },
            ],
        };

        // const formValues = {};

        // for (const [key, value] of data.entries()) {
        //     formValues[key] = value;
        // }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setIsSubmitting(true);

        axios
            .post(updateShipmentSettingsUrl, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    window.scrollTo(0, 0);

                    setSuccessVisibility(true);
                    setSuccessMessage("Account settings has been successfully updated");

                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000);
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    useEffect(() => {
        axios
            .post(getShipmentPricingURL, UserProfileData())
            .then(function (response) {
                if (response["data"]["success"] === true) {
                    setShipmentPricing(response["data"]);
                }
            })
            .catch(function (error) {
                return error;
            });
    }, []);

    const toggleAramexDialog = (type) => {
        type === "open"
            ? setShowAramexAccountDialogVisibility(true)
            : setShowAramexAccountDialogVisibility(false);
    };

    const toggleDHLDialog = (type) => {
        type === "open"
            ? setShowDHLAccountDialogVisibility(true)
            : setShowDHLAccountDialogVisibility(false);
    };

    return (
        <>
            <p
                style={{
                    fontSize: 22,
                    marginTop: 30,
                    marginBottom: 0,
                    fontWeight: "bold",
                }}
            >
                Account Settings
            </p>
            <span>Set how you would want your business to operate</span>

            {isError === false ? null : (
                <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            )}

            {isSuccess === false ? null : (
                <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
            )}

            <AramexAccountDialog
                isModalVisible={showAramexAccountVisibility}
                handleCloseDialog={(e) => toggleAramexDialog("close")}
            />

            <DHLAccountDialog
                isModalVisible={showDHLAccountVisibility}
                handleCloseDialog={(e) => toggleDHLDialog("close")}
            />

            <div className="row mt-4">
                <form ref={form} onSubmit={updateAccountSettings}>
                    <div className="col-xl-8 col-md-12">
                        {/* <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderTop: "1px dashed #BFC9CA", borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Allowed Shipments</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="intracity_shipment" checked={intracityShipment} onChange={(e) => toggleShipmentTypeCheckBox("intracityShipment")}
                                           className="form-check-input" type="checkbox" value="1"  />
                                    <label className="form-check-label" htmlFor="allowedShipment">
                                        Intracity Shipment
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="interstate_shipment" checked={interstateShipment} onChange={(e) => toggleShipmentTypeCheckBox("interstateShipment")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="allowedShipment">
                                            Interstate Shipment
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="international_shipment" checked={internationalShipment} onChange={(e) => toggleShipmentTypeCheckBox("internationalShipment")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="allowedShipment">
                                            International Shipment
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Intracity Vehicles</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="intracity_bike" checked={intracityBikeVehicle} onChange={(e) => toggleShipmentVehicleCheckBox("intracityBikeVehicle")}
                                           className="form-check-input" type="checkbox" value="1"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Motor Bike
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="intracity_car" checked={intracityCarVehicle} onChange={(e) => toggleShipmentVehicleCheckBox("intracityCarVehicle")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Car
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="intracity_van" checked={intracityVanVehicle} onChange={(e) => toggleShipmentVehicleCheckBox("intracityVanVehicle")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Mini Van
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="intracity_truck" checked={intracityTruckVehicle} onChange={(e) => toggleShipmentVehicleCheckBox("intracityTruckVehicle")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Truck
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div
                            className="d-flex flex-row justify-content-between align-items-center py-2"
                            style={{ borderBottom: "1px dashed #BFC9CA" }}
                        >
                            <div className="d-flex flex-row" style={{ width: "100%" }}>
                                <div style={{ width: "45%" }}>
                                    <span style={{ color: "#2C3E50" }}>Intracity Processor</span>
                                </div>
                                <div style={{ width: "55%" }}>
                                    <input
                                        name="intracity_processor"
                                        checked={intracitySelfProcessor}
                                        onChange={(e) => toggleLocalShipmentProcessor("Self")}
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled={!availableCarriers?.local?.self}
                                        value="Self"
                                    />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Self
                                    </label>

                                    <div style={{ marginTop: 10 }}>
                                        <input
                                            name="intracity_processor"
                                            checked={intracityDHLProcessor}
                                            onChange={(e) => toggleLocalShipmentProcessor("DHL")}
                                            className="form-check-input"
                                            type="checkbox"
                                            // disabled={!availableCarriers?.local?.dhl}
                                            value="DHL"
                                        />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            DHL
                                        </label>
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <input
                                            name="intracity_processor"
                                            checked={intracityTopship}
                                            onChange={(e) => toggleLocalShipmentProcessor("Topship")}
                                            className="form-check-input"
                                            // disabled={!availableCarriers?.local?.topship}
                                            type="checkbox"
                                            value="Topship"
                                        />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Topship
                                        </label>
                                    </div>

                                    {/* <div style={{marginTop:10}}>
                                        <input name="intracity_processor" checked={intracityTerminalProcessor} onChange={(e) => toggleIntracityProcessor("intracityTerminalProcessor")}
                                               className="form-check-input" type="radio" value="Terminal"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Terminal
                                        </label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex flex-row justify-content-between align-items-center py-2"
                            style={{ borderBottom: "1px dashed #BFC9CA" }}
                        >
                            <div className="d-flex flex-row" style={{ width: "100%" }}>
                                <div style={{ width: "45%" }}>
                                    <span style={{ color: "#2C3E50" }}>Interstate Processor</span>
                                </div>
                                <div style={{ width: "55%" }}>
                                    {/* <input name="interstate_processor"  checked={interstateSelfProcessor} onChange={(e) => toggleInterstateProcessor("interstateSelfProcessor")}
                                           className="form-check-input" type="radio" value="Self"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Self
                                    </label> */}

                                    <div>
                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={interstateDHLProcessor}
                                                onChange={(e) =>
                                                    toggleInterStateShipmentProcessor("DHL")
                                                }
                                                className="form-check-input"
                                                // disabled={!availableCarriers?.interstate?.dhl}
                                                type="checkbox"
                                                value="1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="interstateDHL"
                                            >
                                                DHL
                                                {/* <span onClick={(e) => toggleDHLDialog("open")} style={{marginLeft:76, fontSize:13, color: "#008561", cursor: "pointer"}}>Update DHL Account</span> */}
                                            </label>
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={interstateRedstar}
                                                onChange={(e) =>
                                                    toggleInterStateShipmentProcessor("Redstar")
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                // disabled={!availableCarriers?.interstate?.redstar}
                                                value="1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="interstateRedstar"
                                            >
                                                Redstar
                                                {/* <span style={{marginLeft:50, fontSize:13, color: "#008561", cursor: "pointer"}}>Update Redstar Account</span> */}
                                            </label>
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={interstateTopship}
                                                onChange={(e) =>
                                                    toggleInterStateShipmentProcessor("Topship")
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                // disabled={!availableCarriers?.interstate?.topship}
                                                value="1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="interstateRedstar"
                                            >
                                                Topship
                                                {/* <span style={{marginLeft:50, fontSize:13, color: "#008561", cursor: "pointer"}}>Update Redstar Account</span> */}
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div style={{marginTop:10}}>
                                        <input name="interstate_processor" checked={interstateTerminalProcessor} onChange={(e) => toggleInterstateProcessor("interstateTerminalProcessor")}
                                               className="form-check-input" type="radio" value="Terminal"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Terminal
                                        </label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex flex-row justify-content-between align-items-center py-2"
                            style={{ borderBottom: "1px dashed #BFC9CA" }}
                        >
                            <div className="d-flex flex-row" style={{ width: "100%" }}>
                                <div style={{ width: "45%" }}>
                                    <span style={{ color: "#2C3E50" }}>
                                        International Processor
                                    </span>
                                </div>
                                <div style={{ width: "55%" }}>
                                    {/* <input name="international_processor" checked={internationalSelfProcessor} onChange={(e) => toggleInternationalProcessor("internationalSelfProcessor")}
                                           className="form-check-input" type="radio" value="Self"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Self
                                    </label> */}

                                    <div>
                                        <input
                                            name="interstate_carriers"
                                            checked={internationalAAJ}
                                            onChange={(e) =>
                                                toggleInternationalShipmentProcessor("AAJ")
                                            }
                                            className="form-check-input"
                                            type="checkbox"
                                            // disabled={!availableCarriers?.international?.aaj}
                                            value="1"
                                        />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            AAJ
                                            {/* <span style={{marginLeft:78, fontSize:13, color: "#008561", cursor: "pointer"}}>Update UPS Account</span> */}
                                        </label>

                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={internationalAramex}
                                                onChange={(e) =>
                                                    toggleInternationalShipmentProcessor("Aramex")
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                // disabled={!availableCarriers?.international?.aramex}
                                                value="1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="localShipment"
                                            >
                                                Aramex
                                                {/* <span onClick={(e) => toggleAramexDialog("open")} style={{marginLeft:50, fontSize:13, color: "#008561", cursor: "pointer"}}>Update Aramex Account</span> */}
                                            </label>
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={internationalDHLProcessor}
                                                onChange={(e) =>
                                                    toggleInternationalShipmentProcessor("DHL")
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                // disabled={!availableCarriers?.international?.dhl}
                                                value="1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="localShipment"
                                            >
                                                DHL
                                                {/* <span onClick={(e) => toggleDHLDialog("open")} style={{marginLeft:76, fontSize:13, color: "#008561", cursor: "pointer"}}>Update DHL Account</span> */}
                                            </label>
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={internationalFedExEconomy}
                                                onChange={(e) =>
                                                    toggleInternationalShipmentProcessor("FedEx Economy")
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                // disabled={
                                                //     !availableCarriers?.international?.fedex_economy
                                                // }
                                                value="1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="localShipment"
                                            >
                                                FedEx International Economy®
                                                {/* <span style={{marginLeft:62, fontSize:13, color: "#008561", cursor: "pointer"}}>Update FedEx Account</span> */}
                                            </label>
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={internationalFedExPriority}
                                                onChange={(e) =>
                                                    toggleInternationalShipmentProcessor("FedEx Priority")
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                // disabled={
                                                //     !availableCarriers?.international?.fedex_priority
                                                // }
                                                value="1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="localShipment"
                                            >
                                                FedEx International Priority®
                                                {/* <span style={{marginLeft:62, fontSize:13, color: "#008561", cursor: "pointer"}}>Update FedEx Account</span> */}
                                            </label>
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={internationalTopship}
                                                onChange={(e) =>
                                                    toggleInternationalShipmentProcessor("Topship")
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                // disabled={!availableCarriers?.import?.topship}
                                                value="Topship"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="importShipment"
                                            >
                                                Topship
                                            </label>
                                        </div>

                                        <div style={{ marginTop: 10 }}>
                                            <input
                                                name="interstate_carriers"
                                                checked={internationalUPS}
                                                onChange={(e) =>
                                                    toggleInternationalShipmentProcessor("UPS")
                                                }
                                                className="form-check-input"
                                                type="checkbox"
                                                // disabled={!availableCarriers?.international?.ups}
                                                value="1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="localShipment"
                                            >
                                                UPS
                                                {/* <span style={{marginLeft:78, fontSize:13, color: "#008561", cursor: "pointer"}}>Update UPS Account</span> */}
                                            </label>
                                        </div>
                                    </div>

                                    {/* <div style={{marginTop:10}}>
                                        <input name="international_processor" checked={internationalTerminalProcessor} onChange={(e) => toggleInternationalProcessor("internationalTerminalProcessor")}
                                               className="form-check-input" type="radio" value="Terminal"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Terminal
                                        </label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex flex-row justify-content-between align-items-center py-2"
                            style={{ borderBottom: "1px dashed #BFC9CA" }}
                        >
                            <div className="d-flex flex-row" style={{ width: "100%" }}>
                                <div style={{ width: "45%" }}>
                                    <span style={{ color: "#2C3E50" }}>Import Processor</span>
                                </div>

                                <div style={{ width: "55%" }}>
                                    <input
                                        name="import_processor"
                                        checked={importAramex}
                                        onChange={(e) => toggleImportShipmentProcessor("Aramex")}
                                        className="form-check-input"
                                        // disabled={!availableCarriers?.import?.aramex}
                                        type="checkbox"
                                        value="Aramex"
                                    />
                                    <label className="form-check-label" htmlFor="importShipment">
                                        Aramex
                                    </label>
                                    <div style={{ marginTop: 10 }}>
                                        <input
                                            name="import_processor"
                                            checked={importDHLProcessor}
                                            onChange={(e) => toggleImportShipmentProcessor("DHL")}
                                            className="form-check-input"
                                            type="checkbox"
                                            // disabled={!availableCarriers?.import?.dhl}
                                            value="DHL"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="importShipment"
                                        >
                                            DHL
                                        </label>
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <input
                                            name="import_processor"
                                            checked={importFedExEconomy}
                                            onChange={(e) =>
                                                toggleImportShipmentProcessor("FedEx Economy")
                                            }
                                            className="form-check-input"
                                            // disabled={!availableCarriers?.import?.fedex_economy}
                                            type="checkbox"
                                            value="FedEx Economy"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="importShipment"
                                        >
                                            FedEx International Economy®
                                        </label>
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <input
                                            name="import_processor"
                                            checked={importFedExPriority}
                                            onChange={(e) =>
                                                toggleImportShipmentProcessor("FedEx Priority")
                                            }
                                            className="form-check-input"
                                            // disabled={!availableCarriers?.import?.fedex_priority}
                                            type="checkbox"
                                            value="FedEx Priority"
                                        />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            FedEx International Priority®
                                        </label>
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <input
                                            name="import_processor"
                                            checked={importTopship}
                                            onChange={(e) => toggleImportShipmentProcessor("Topship")}
                                            className="form-check-input"
                                            type="checkbox"
                                            // disabled={!availableCarriers?.import?.topship}
                                            value="Topship"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="importShipment"
                                        >
                                            Topship
                                        </label>
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <input
                                            name="import_processor"
                                            checked={importUPS}
                                            onChange={(e) => toggleImportShipmentProcessor("UPS")}
                                            className="form-check-input"
                                            // disabled={!availableCarriers?.import?.ups}
                                            type="checkbox"
                                            value="UPS"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="importShipment"
                                        >
                                            UPS
                                        </label>
                                    </div>

                                    {/* <div style={{marginTop:10}}>
                                        <input name="intracity_processor" checked={intracityTerminalProcessor} onChange={(e) => toggleIntracityProcessor("intracityTerminalProcessor")}
                                               className="form-check-input" type="radio" value="Terminal"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Terminal
                                        </label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Customer cancels shipment</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="cancel_shipment"
                                           className="form-check-input" type="radio" value="yes"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Refund to customer wallet
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="cancel_shipment"
                                               className="form-check-input" type="radio" value="yes"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Refund directly to bank account
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="cancel_shipment"
                                               className="form-check-input" type="radio" value="yes"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Do nothing
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Payment Methods</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="cash" checked={cashPaymentMethod} onChange={(e) => togglePaymentMethod("cash")}
                                           className="form-check-input" type="checkbox" value="1"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Cash
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="bank_transfer" checked={directTransferPaymentMethod} onChange={(e) => togglePaymentMethod("directTransfer")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Direct Transfer
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="card_payment" checked={cardPaymentMethod} onChange={(e) => togglePaymentMethod("cardPayment")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Card Payment
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="wallet" checked={walletPaymentMethod} onChange={(e) => togglePaymentMethod("wallet")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Wallet
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Transporter Settings</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="auto_arrange_routes"
                                           className="form-check-input" type="checkbox" value="yes"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Auto Arrange Routes for Intracity Shipments
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="positional_marking"
                                               className="form-check-input" type="checkbox" value="yes"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Positional Marking
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="enforced_tracking"
                                               className="form-check-input" type="checkbox" value="yes"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Enforced Tracking
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="d-flex flex-row my-5 justify-content-end">
                            <SubmitButton type="submit" className="confirm-button">
                                {isSubmitting === false ? (
                                    <span className="button-text">UPDATE SETTINGS</span>
                                ) : (
                                    <span className="spinner-border spinner-grow-sm" />
                                )}
                            </SubmitButton>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AccountSettings;
