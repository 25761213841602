import "../styles/sidebar.scss";
import * as BoxIcons from "react-icons/bi";
import { SidebarData } from "./SidebarData";
import SingleMenu from "./SingleMenu";
import { SessionManagement } from "../library/SessionManagement";
import styled from "styled-components";
import {useState} from "react";

const ProfileImage = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
`;

const Sidebar = () => {
    const companyName = SessionManagement.getCompanyName();
    const companyOperatorID =SessionManagement.getCompanyOperatorID();
    const companyLogoURL = SessionManagement.getCompanyLogoURL();

    return (
    <div className="sidebar d-none d-xl-block">
      <div className="logo">
        <div className="d-flex flex-row mt-1">
          <ProfileImage
            src={companyLogoURL}
            className="avatar"
            alt=""
          />
          <div className="details d-flex flex-column align-items-start">
            <p className="operator-name">{companyName}</p>
            <span className="operator-id">Operator ID: {companyOperatorID}</span>
          </div>
        </div>
      </div>

      <hr />

      <div className="menu">
        <ul className="menu-list">
          {SidebarData.map((item, index) => {
              if (companyName !== "520 Logistics" && item.title === "Partners") {
                  return null;
              }
            return <SingleMenu item={item} key={index} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
