import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {getShipmentsByUserURL, getShipmentsURL} from "../../../library/URLs";
import styled from "styled-components";


const CustomerOverview = ({primaryPickupAddress, secondaryPickupAddress, registrationTimestamp, lastActivityTimestamp}) => {
    const editCustomerProfile = () => {

    };

    return (
        <div className="card bg-light border-0 shadow">
            <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                    <span className="header">Customer Overview</span>
                    <input className="confirm-button" type="button" onClick={editCustomerProfile} value="EDIT PROFILE" />
                </div>

                <div className="customer-overview-container pt-3">
                    <div className="row mb-4">
                        <label className="col-lg-4">Registration Date</label>
                        <div className="col-lg-8">{registrationTimestamp}</div>
                    </div>
                    <div className="row mb-4">
                        <label className="col-lg-4">Last Activity Date</label>
                        <div className="col-lg-8">{lastActivityTimestamp}</div>
                    </div>
                    <div className="row mb-4">
                        <label className="col-lg-4">Primary Pickup Address</label>
                        <div className="col-lg-8">{primaryPickupAddress === "" ? "Undefined" : primaryPickupAddress}</div>
                    </div>
                    <div className="row mb-4">
                        <label className="col-lg-4">Secondary Pickup Address</label>
                        <div className="col-lg-8">{secondaryPickupAddress === "" ? "Undefined" : secondaryPickupAddress}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerOverview;