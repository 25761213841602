import styled from "styled-components";

const ParcelImages = ({ parcelImages }) => {

  let parcel_images = parcelImages ? parcelImages?.split("|") : [];
  console.log("afb ", parcel_images);

  return (
    <div className="col-xl-6 col-md-12">
      <span className="header">Parcel Images</span>
      <div className="card bg-light border-0 shadow shipper-container">
        <div className="card-body shipper-container">
            {
              parcel_images?.length < 1 ? (
                <NotProcessed>
                  <p>No parcel Image(s) uploaded</p>
                </NotProcessed>
              ) : (
                <div>
                  {
                    parcel_images?.map((img, id) => (
                      <div className="d-flex justify-content-between" key={id}>
                        <div>
                          <span className="title">Parcel {id+1 < 10 ? `0${id+1}` : id+1}</span>
                          <p>
                            Click{" "}
                            <a
                              style={{ textDecoration: "none" }}
                              target="_blank"
                              rel="noreferrer"
                              href={img}
                            >
                              here
                            </a>{" "}
                            to view the image
                          </p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }
        </div>
      </div>
    </div>
  );
};

const NotProcessed = styled.div`
  height: 140px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ParcelImages;
