import {Col, Modal, Row} from "react-bootstrap";
import {createRef, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import { getPackagingURL } from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {BiX} from "react-icons/bi";
import * as React from "react";
import { toast } from "react-toastify";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const EditPackageDimensionsDialog = ({ isModalVisible, handleCloseDialog, packageObj, handleSubmit}) => {
    const [formData, setFormData] = useState({
        package_length: "",
        package_width: "",
        package_height: ""
    });
    const [containerTypes, setContainerTypes] = useState([]);
    const [customDimension, setCustomDimension] = useState("No");
    const [selectedPackage, setSelectedPackage] = useState();

    React.useEffect(() => {
        if(isModalVisible) {
            setFormData({
                ...formData,
                package_length: packageObj?.package_length,
                package_width: packageObj?.package_width,
                package_height: packageObj?.package_height
            });

            if(packageObj?.selected_package) {
                setSelectedPackage(packageObj?.selected_package);
            }

            if(packageObj?.custom_dimension) {
                setCustomDimension(packageObj?.custom_dimension);
            }
        };
        // eslint-disable-next-line
    }, [isModalVisible]);

    React.useEffect(() => {
        axios
            .post(getPackagingURL, UserProfileData())
            .then(function (response) {
            console.log(response);
            if (response["data"]["success"] === true) {
                const packaging = response["data"]["data"];
                console.log(packaging);

                setContainerTypes(packaging);
            }
            })
            .catch(function (error) {
            return error;
            });
    }, []);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleInput = (e) => {
        const {name, value} = e.target;

        setFormData({
            ...formData,
            [name]: value
        })
    };

    const handlePackageSelection = (e) => {
        const { value } = e.target;
        setSelectedPackage(value);
        let selectedContainer = containerTypes.find(item => item?.id === parseInt(value));
        console.log("pow", value);
        console.log("oiie", containerTypes);
        console.log("oasoe", selectedContainer);
        setFormData({
            ...formData,
            package_length: selectedContainer?.length,
            package_width: selectedContainer?.width,
            package_height: selectedContainer?.height
        })
    };

    const onSubmit = (e) => {
        e.preventDefault();
        
        if(
            formData?.package_height === "" ||
            formData?.package_length === "" ||
            formData?.package_width === ""
        ) {
            setErrorVisibility(true);
            setErrorMessage("Please ensure to fill all fields!");

            setTimeout(() => {
                setErrorVisibility(false);
            }, 5000);
            return;
        }
        handleSubmit({...formData, selected_package: selectedPackage ?? null, custom_dimension: customDimension});
        handleCloseDialog();
    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">EDIT PACKAGE DIMENSIONS</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <form >
              <div>
                <div className="mt-2" >
                    <label htmlFor="Weight">Use Custom Dimensions</label>
                    <select name="container_type_id" onChange={(e)=>setCustomDimension(e.target.value)} value={customDimension} className="form-select">
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                    </select>
                </div>
                {
                    customDimension === "Yes" ? (
                        <>
                            <div className="mt-3">
                                <label htmlFor="Amount">Length</label>
                                <input name="package_length" className="form-control" type="tel" value={formData?.package_length} onChange={handleInput} />
                            </div>
                            <Row className="mt-2">
                                <Col>
                                    <label htmlFor="User Name">Width</label>
                                    <input name="package_width" className="form-control" type="tel" value={formData?.package_width} onChange={handleInput}/>
                                </Col>
                            </Row>
                            <div className="mt-2">
                                <label htmlFor="Description">Height</label>
                                <input name="package_height" className="form-control" type="tel" value={formData?.package_height} onChange={handleInput} />
                            </div>
                        </>
                    ) : (
                        <div className="mt-2" >
                            <label htmlFor="Weight">Choose Container Type</label>
                            <select name="container_type_id" onChange={handlePackageSelection} value={selectedPackage} className="form-select">
                                <option value="">Choose container type</option>
                                {containerTypes.map((container) => {
                                return (
                                    <option key={container.id} value={container.id}>
                                    {container.name}
                                    </option>
                                );
                                })}
                            </select>
                        </div>
                    )
                }
              </div>
          </form>

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={onSubmit} type="button" className="confirm-button">
            EDIT
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPackageDimensionsDialog;
