import * as BoxIcons from "react-icons/bi";
import * as React from "react";
import FundWalletDialog from "../../../modals/FundWalletDialog";
import {useEffect, useState} from "react";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import axios from "axios";
import {getDHLPickupLocationsURL, getShipmentPricingURL} from "../../../library/URLs";
import {UserProfileData} from "../../../library/constants";
import Interstate3PLPriceSettingsDialog from "../../../modals/Interstate3PLPriceSettingsDialog";
import International3PLPriceSettingsDialog from "../../../modals/International3PLPriceSettingsDialog";


const PriceSettings = () => {
    const [intracityMotorbikePriceSettingsDialogVisibility, setIntracityMotorbikePriceSettingsDialogVisibility] = useState(false);
    const [interstate3PLPriceSettingsDialogVisibility, setInterstate3PLPriceSettingsDialogVisibility] = useState(false);
    const [international3PLPriceSettingsDialogVisibility, setInternational3PLPriceSettingsDialogVisibility] = useState(false);

    const [shipmentPricing, setShipmentPricing] = useState([]);

    const handleCloseModal = () => {
        setIntracityMotorbikePriceSettingsDialogVisibility(false);
        setInterstate3PLPriceSettingsDialogVisibility(false);
        setInternational3PLPriceSettingsDialogVisibility(false);
    };

    const showMotorbikeDialog = () => {
        setIntracityMotorbikePriceSettingsDialogVisibility(!intracityMotorbikePriceSettingsDialogVisibility);
    }

    const showInterstate3PLDialog = () => {
        setInterstate3PLPriceSettingsDialogVisibility(!interstate3PLPriceSettingsDialogVisibility);
    }

    const showInternational3PLDialog = () => {
        setInternational3PLPriceSettingsDialogVisibility(!international3PLPriceSettingsDialogVisibility);
    }

    useEffect(() => {
        axios
            .post(getShipmentPricingURL, UserProfileData())
            .then(function (response) {
                if (response["data"]["success"] === true) {
                    console.log(response);
                    setShipmentPricing(response["data"]);
                }
            })
            .catch(function (error) {
                return error;
            });
    }, [])

    return(
        <>
            <PriceSettingsDialog
                isModalVisible={intracityMotorbikePriceSettingsDialogVisibility}
                handleCloseDialog={handleCloseModal}
                shipmentPricing={shipmentPricing}
            />

            <Interstate3PLPriceSettingsDialog
                isModalVisible={interstate3PLPriceSettingsDialogVisibility}
                handleCloseDialog={handleCloseModal}
                shipmentPricing={shipmentPricing}
            />

            <International3PLPriceSettingsDialog
                isModalVisible={international3PLPriceSettingsDialogVisibility}
                handleCloseDialog={handleCloseModal}
                shipmentPricing={shipmentPricing}
            />
            <p style={{fontSize:22, marginTop:30, marginBottom:0, fontWeight:"bold"}}>Price Settings</p>
            <span>Simple pricing. No hidden fees. Advanced features for your business</span>
            <div className="row mt-4">
                <div className="col-xl-8 col-md-12">
                    <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderTop: "1px dashed #BFC9CA", borderBottom: "1px dashed #BFC9CA"}}>
                        <div className="d-flex flex-column">
                            <span style={{fontSize:18, color:"#2C3E50"}}>Intracity Motorbike Pricing</span>
                            <span style={{fontSize:14}}>Pricing for shipments done within a city by bikes</span>
                        </div>

                        <div onClick={(e) => showMotorbikeDialog()} className="d-flex justify-content-center align-items-center oval-button">
                            <span style={{marginLeft:"15px", marginRight:15, marginTop:5, marginBottom:5, cursor:"pointer"}}>Update</span>
                        </div>
                    </div>
                    {/*<div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>*/}
                    {/*    <div className="d-flex flex-column">*/}
                    {/*        <span style={{fontSize:18, color:"#2C3E50"}}>Intracity Car Pricing</span>*/}
                    {/*        <span style={{fontSize:14}}>Pricing for shipments done within a city by cars</span>*/}
                    {/*    </div>*/}

                    {/*    <div className="d-flex justify-content-center align-items-center oval-button">*/}
                    {/*        <span style={{marginLeft:"15px", marginRight:15, marginTop:5, marginBottom:5, cursor:"pointer"}}>Update</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>*/}
                    {/*    <div className="d-flex flex-column">*/}
                    {/*        <span style={{fontSize:18, color:"#2C3E50"}}>Intracity Minivan Pricing</span>*/}
                    {/*        <span style={{fontSize:14}}>Pricing for shipments done within a city by vans</span>*/}
                    {/*    </div>*/}

                    {/*    <div className="d-flex justify-content-center align-items-center oval-button">*/}
                    {/*        <span style={{marginLeft:"15px", marginRight:15, marginTop:5, marginBottom:5, cursor:"pointer"}}>Update</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>*/}
                    {/*    <div className="d-flex flex-column">*/}
                    {/*        <span style={{fontSize:18, color:"#2C3E50"}}>Interstate Van Pricing</span>*/}
                    {/*        <span style={{fontSize:14}}>Pricing for shipment done across a state by vans</span>*/}
                    {/*    </div>*/}

                    {/*    <div className="d-flex justify-content-center align-items-center oval-button">*/}
                    {/*        <span style={{marginLeft:"15px", marginRight:15, marginTop:5, marginBottom:5, cursor:"pointer"}}>Update</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>*/}
                    {/*    <div className="d-flex flex-column">*/}
                    {/*        <span style={{fontSize:18, color:"#2C3E50"}}>Interstate Truck Pricing</span>*/}
                    {/*        <span style={{fontSize:14}}>Pricing for shipments done across a state by trucks</span>*/}
                    {/*    </div>*/}

                    {/*    <div className="d-flex justify-content-center align-items-center oval-button">*/}
                    {/*        <span style={{marginLeft:"15px", marginRight:15, marginTop:5, marginBottom:5, cursor:"pointer"}}>Update</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                        <div className="d-flex flex-column">
                            <span style={{fontSize:18, color:"#2C3E50"}}>Interstate 3PL Pricing</span>
                            <span style={{fontSize:14}}>Set your commission for 3PL interstate shipments</span>
                        </div>

                        <div onClick={(e) => showInterstate3PLDialog()} className="d-flex justify-content-center align-items-center oval-button">
                            <span style={{marginLeft:"15px", marginRight:15, marginTop:5, marginBottom:5, cursor:"pointer"}}>Update</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                        <div className="d-flex flex-column">
                            <span style={{fontSize:18, color:"#2C3E50"}}>International 3PL Pricing</span>
                            <span style={{fontSize:14}}>Set your commission for 3PL international shipments</span>
                        </div>

                        <div onClick={(e) => showInternational3PLDialog()} className="d-flex justify-content-center align-items-center oval-button">
                            <span style={{marginLeft:"15px", marginRight:15, marginTop:5, marginBottom:5, cursor:"pointer"}}>Update</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PriceSettings;