import { Button, Col, Modal, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import {
    createInternationalShipmentURL,
    fundUserWalletURL,
    getContractDetailsUserURL,
    getShipmentPricingURL,
    getShipmentsURL,
    makeContractUserURL,
    topupUserWalletURL,
    updateAccountSettingsURL,
    updatePriceSettingsUrl,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BiX } from "react-icons/bi";
import * as React from "react";
import { SessionManagement } from "../library/SessionManagement";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor: pointer;
  padding-left: 2px;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const MotorbikePriceSettingsDialog = ({
    isModalVisible,
    handleCloseDialog,
    shipmentPricing,
}) => {
    const [shipmentDeliveryType, setShipmentDeliveryType] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [selectedPricingType, setSelectedPricingType] = useState("");

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [contentFields, setContentFields] = useState(1);

    const [pricingForm, setPricingForm] = useState({
        base_from: "",
        base_to: "",
        base_amount: "",
        per_km_amount: "",
        vehicle_type: "motorbike",
        pricing_type: "",
        price_ranges: [
            {
                from_km: "",
                to_km: "",
                amount: "",
            },
        ],
    });

    const setIntracityPricing = (e) => {
        e.preventDefault();

        const newData = { ...pricingForm, ...UserProfileData() };

        if(UserProfileData().company_id === "123") {
            newData["delivery_type"] = shipmentDeliveryType;
        }

        console.log(newData);

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(updatePriceSettingsUrl, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage(
                        "Successfully updated price setting"
                    );

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog();
                    }, 3000);
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    const createContentFields = () => {
        return pricingForm.price_ranges.map((pricing, id) => (
            <Row className="form-row mb-3" key={id} >
                <Col>
                    <label htmlFor="From">From (KM)</label>
                    <input
                        name={`from_km`}
                        className="form-control"
                        type="text"
                        value={pricing?.from_km}
                        onChange={(e) => handleRange(e, id)}
                        placeholder=""
                    />
                </Col>

                <Col>
                    <label htmlFor="To">To (KM)</label>
                    <input
                        name={`to_km`}
                        className="form-control"
                        type="text"
                        value={pricing?.to_km}
                        onChange={(e) => handleRange(e, id)}
                        placeholder=""
                    />
                </Col>

                <Col>
                    <label htmlFor="Quantity">Amount</label>
                    <input
                        name={`amount`}
                        className="form-control"
                        type="tel"
                        value={pricing?.amount}
                        onChange={(e) => handleRange(e, id)}
                        placeholder=""
                    />
                </Col>
            </Row>
        ))
    };

    const handleContentFieldsIncrease = () => {
        if (pricingForm.price_ranges.length < 5) {
            let updatedList = [...pricingForm.price_ranges];
            updatedList.push({
                from_km: "",
                to_km: "",
                amount: "",
            });
            setPricingForm({
                ...pricingForm,
                price_ranges: updatedList
            })
        }
    };

    const handleContentFieldsDecrease = () => {
        if (pricingForm.price_ranges.length > 1) {
            let updatedList = [...pricingForm.price_ranges]
            updatedList.pop();
            setPricingForm({
                ...pricingForm,
                price_ranges: updatedList
            })
        }
    };

    const onChangePricingType = (event) => {
        if (event.target.value === "Base") {
            setSelectedPricingType("Base");
        } else if (event.target.value === "Range") {
            setSelectedPricingType("Range");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPricingForm({
            ...pricingForm,
            [name]: value
        })
    };

    const handleRange = (e, id) => {
        const { name, value } = e.target;

        let updateList = [...pricingForm.price_ranges];

        updateList[id][name] = value;

        setPricingForm({
            ...pricingForm,
            price_ranges: updateList
        })
    }

    const setMotorbikePricing = () => { };


    const [pricings, setPricings] = useState([]);

    useEffect(() => {
        if(UserProfileData().company_id !== "123") {
            setShipmentDeliveryType("Standard");
        }
        axios
        .post(getShipmentPricingURL, UserProfileData())
        .then(function (response) {
            if (response["data"]["success"] === false) {
            } else {
                if (response["data"].pricing_type === "Range") {
                    setSelectedPricingType("Range");
                    setPricingForm({
                        ...pricingForm,
                        pricing_type: "Range"
                    });
                    if (Array.isArray(response["data"].data)) {
                        let count = 0;
                        response["data"].data.forEach(function (key, value) {
                            if (
                                response["data"].data[value].type === "motorbike" &&
                                response["data"].data[value].delivery_type === "Standard" &&
                                response["data"].data[value].status === "active"
                            ) {
                                count++;
                                console.log(response["data"].data[value].delivery_type);
                            }
                        });

                        setContentFields(count);
                    }
                } else if (response["data"].pricing_type === "Base") {
                    console.log("Pricing type is base");
                    setSelectedPricingType("Base");
                    let pricing = {
                        ...pricingForm,
                        pricing_type: "Base"
                    };
                    setPricings(response["data"].data);
                    if(response["data"].data?.length === 1) {
                        pricing["base_amount"] = response["data"].data[0]?.base_amount;
                        pricing["base_from"] = response["data"].data[0]?.base_from_distance;
                        pricing["base_to"] = response["data"].data[0]?.base_to_distance;
                        pricing["per_km_amount"] = response["data"].data[0]?.per_km_amount;
                    }
                    setPricingForm(pricing);
                } else {
                    console.log("No pricing detected");
                }
            }
        })
        .catch(function (error) {
            return error;
        });
    }, []);

    console.log(shipmentPricing.pricing_type);

    const handleShipmentDeliveryType = (e) => {
        const { value } = e.target;

        setShipmentDeliveryType(value);

        const pricing_data = pricings?.find(price => price?.delivery_type === value);

        if(pricing_data && selectedPricingType === "Base") {
            setPricingForm({
                ...pricingForm,
                base_from: pricing_data?.base_from_distance,
                base_to: pricing_data?.base_to_distance,
                base_amount: pricing_data?.base_amount,
                per_km_amount: pricing_data?.per_km_amount,
            });
        }
    }

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 style={{ marginTop: 10 }} className="header">
                    SET MOTORBIKE PRICING
                </h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                {isError === false ? null : (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                    <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}

                <div>
                    {/*<div className="d-flex flex-row justify-content-evenly">*/}
                    {/*    <div style={{width: "100%", textAlign: "center", cursor:"pointer"}}><span>Standard Pricing</span></div>*/}
                    {/*    <div  style={{width: "100%", textAlign: "center", cursor:"pointer"}}><span>Express Pricing</span></div>*/}
                    {/*</div>*/}
                    {
                        UserProfileData().company_id === "123" && (
                            <Row className="mt-2">
                                <Col>
                                    <label htmlFor="Sender Postal">Shipment Delivery Type</label>
                                    <select
                                        onChange={handleShipmentDeliveryType}
                                        value={shipmentDeliveryType}
                                        name="pricing_type"
                                        className="form-select"
                                    >
                                        <option value="">Choose Delivery Type</option>
                                        <option value="BOT Express Flash">BOT Express Flash</option>
                                        <option value="BOT Express Rapid">BOT Express Rapid</option>
                                        <option value="BOT Express Flex">BOT Express Flex</option>
                                        <option value="BOT Express Saver">BOT Express Saver</option>
                                    </select>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        (UserProfileData().company_id !== "123" 
                        || (UserProfileData().company_id === "123" && shipmentDeliveryType !== "")) &&
                        (
                            <Row className="mt-2">
                                <Col>
                                    <label htmlFor="Sender Postal">Pricing Type</label>
                                    <select
                                        onChange={handleChange}
                                        value={pricingForm.pricing_type}
                                        name="pricing_type"
                                        className="form-select"
                                    >
                                        <option value="">Choose Pricing Type</option>
                                        <option value="Base">Base Pricing</option>
                                        <option value="Range">Range Pricing</option>
                                    </select>
                                </Col>
                            </Row>
                        )
                    }

                    {pricingForm.pricing_type === "Base" ? shipmentDeliveryType !== "" && (
                        <div>
                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="Base From">Base From (KM)</label>
                                    <input
                                        name="base_from"
                                        className="form-control"
                                        type="number"
                                        value={pricingForm.base_from}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col>
                                    <label htmlFor="Base To">Base To (KM)</label>
                                    <input
                                        name="base_to"
                                        className="form-control"
                                        type="number"
                                        value={pricingForm.base_to}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="Sender Postal">Base Price</label>
                                    <input
                                        name="base_amount"
                                        className="form-control"
                                        type="number"
                                        value={pricingForm.base_amount}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="Sender Postal">Additional Price Per KM</label>
                                    <input
                                        name="per_km_amount"
                                        className="form-control"
                                        type="number"
                                        value={pricingForm.per_km_amount}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ) : shipmentDeliveryType !== "" && pricingForm.pricing_type === "Range" ? (
                        <div className="mt-3">
                            {createContentFields()}
                            <div className="d-flex justify-content-center">
                                <ContentFieldsActionButton
                                    onClick={handleContentFieldsDecrease}
                                >
                                    -
                                </ContentFieldsActionButton>
                                <ContentFieldsActionButton
                                    onClick={handleContentFieldsIncrease}
                                >
                                    +
                                </ContentFieldsActionButton>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
                <SubmitButton type="submit" onClick={setIntracityPricing} className="confirm-button">
                    {isSubmitting === false ? (
                        <span className="button-text">SET MOTORBIKE PRICING</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default MotorbikePriceSettingsDialog;
